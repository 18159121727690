import React, { useState } from "react";
import "./InsertEmbed.scss";
import { Modal } from "./Modal";
import { AppState } from "../types";
import { nanoid } from "nanoid";
import {
  GoogleDocsIcon,
  GoogleSheetIcon,
  GoogleSlidesIcon,
  YoutubeIcon,
} from "./icons";
import { EmbedType } from "../constants";
import { Button, Card, Flex, Input } from "antd";
import Meta from "antd/es/card/Meta";
import Title from "antd/es/typography/Title";
import { t } from "../i18n";

export const InsertEmbed: React.FC<{
  onClose?: () => void;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
}> = ({ onClose, appState, setAppState }) => {
  const [url, setUrl] = useState<string>();
  const [error, setError] = useState("");
  const [type, setType] = useState<(keyof typeof EmbedType)>("youtube")


  const regYoutube =
    /^(?:http(?:s)?:\/\/)?(?:www\.)?youtu(?:be\.com|\.be)\/(embed\/|watch\?v=|shorts\/|playlist\?list=|embed\/videoseries\?list=)?([a-zA-Z0-9_-]+)(?:\?t=|&t=|\?start=|&start=)?([a-zA-Z0-9_-]+)?[^\s]*$/;

  const regGoogledoc = /^(https:\/\/docs.google.com\/document\/d\/)/;
  const regGoogleSheet = /^(https:\/\/docs.google.com\/spreadsheets\/d\/)/;
  const regGoogleSlide = /^(https:\/\/docs.google.com\/presentation\/d\/)/;
  const regDrawio = /^(https:\/\/draw.service.imago.us)/;

  const allowedDomains = new Set([
    "youtube.com",
    "youtu.be",
    "docs.google.com",
    "draw.service.imago.us"
  ]);
  const validateHostname = (url: string): boolean => {
    try {
      const { hostname } = new URL(url);

      const bareDomain = hostname.replace(/^www\./, "");
      const bareDomainWithFirstSubdomainWildcarded = bareDomain.replace(
        /^([^.]+)/,
        "*",
      );

      return (
        allowedDomains.has(bareDomain) ||
        allowedDomains.has(bareDomainWithFirstSubdomainWildcarded)
      );
    } catch (error) {
      // ignore
    }
    return false;
  };

  const getEmbedLink = (link: string | null | undefined): string | null => {
    if (!link) {
      return null;
    }
    switch (type) {
      case "youtube":
        const ytLink = link.match(regYoutube);

        if (ytLink?.[2]) {
          const time = ytLink[3] ? `&start=${ytLink[3]}` : ``;
          switch (ytLink[1]) {
            case "embed/":
            case "watch?v=":
            case "shorts/":
              link = `https://www.youtube.com/embed/${ytLink[2]}?enablejsapi=1${time}`;
              break;
            case "playlist?list=":
            case "embed/videoseries?list=":
              link = `https://www.youtube.com/embed/videoseries?list=${ytLink[2]}&enablejsapi=1${time}`;
              break;
            default:
              link = `https://www.youtube.com/embed/${ytLink[2]}?enablejsapi=1${time}`;
              break;
          }

          return link;
        }
        break;
      case "googleDocs":
        if (regGoogledoc.test(link)) {
          return link;
        }
        break;
      case "googleSheet":
        if (regGoogleSheet.test(link)) {
          return link;
        }
        break;
      case "googleSlides":
        if (regGoogleSlide.test(link)) {
          return link;
        }
        break;
      case "drawio":
        if (regDrawio.test(link)) {
          return link;
        }
        break;
    }

    return null;
  };

  const onCreate = () => {
    if (url && validateHostname(url)) {
      const embedLink = getEmbedLink(url);
      if (embedLink) {
        setAppState({
          webEmbed: [
            ...appState.webEmbed,
            { url: embedLink, id: nanoid(), hide: false, type, zIndex: appState.webEmbed.filter(w => !w.removed).length + 1 },
          ],
          openSidebar: null,
        });
        onClose && onClose();
        return;
      }
    }
    setError(t("labels.invalid_url"));
  };
  const selectStype = {
    background: "#ccc",
    boxShadow: "inset 0px 3px 3px rgba(0, 0, 0, 0.5)"
  };

  return (
    <div className="insert-embed">
      <Flex justify="center" gap={10} wrap="wrap" >
        <Card
          hoverable
          classNames={{ body: "card" }}
          onClick={() => setType("youtube")}
          style={type == "youtube" ? selectStype : {}}
        >
          {YoutubeIcon}
          <Meta title={t("labels.youtube")} />
        </Card>
        <Card
          hoverable
          classNames={{ body: "card" }}
          onClick={() => setType("googleDocs")}
          style={type == "googleDocs" ? selectStype : {}}
        >
          {GoogleDocsIcon}
          <Meta title={t("labels.google_docs")} />
        </Card>
        <Card
          hoverable
          classNames={{ body: "card" }}
          onClick={() => setType("googleSheet")}
          style={type == "googleSheet" ? selectStype : {}}
        >
          {GoogleSheetIcon}
          <Meta title={t("labels.google_sheet")} />
        </Card>
        <Card
          hoverable
          classNames={{ body: "card" }}
          onClick={() => setType("googleSlides")}
          style={type == "googleSlides" ? selectStype : {}}
        >
          {GoogleSlidesIcon}
          <Meta title={t("labels.google_slides")} />
        </Card>
      </Flex>
      <Title level={5}>{t("winTabList.links")}</Title>
      <div className="field">
        <Input value={url} onChange={(e) => setUrl(e.target.value)} placeholder={t("winTabList.links")}></Input>
        <Button type="primary" onClick={() => onCreate()}>{t("labels.btn_insert")}</Button>
      </div>
      <div className="error">{error}</div>
    </div>
  );
};
