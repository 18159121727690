
import React, { useContext, useEffect, useState } from "react";
import { Language, t } from "../i18n";
import { JaaSMeeting, JitsiMeeting } from '@jitsi/react-sdk';

interface FlashCollabProps {
    roomId: string,
    configOverwrite?: any,
    userInfo?: {
        /**
         * The participant display name.
         */
        displayName: string;
        /**
         * The participant email address.
         */
        email: string;
    }
}

const FlashCollab = ({ roomId ,configOverwrite,userInfo}: FlashCollabProps) => {
    const generateRoomName = () => `JitsiMeetRoomNo${Math.random() * 100}-${Date.now()}`;
    const handleJitsiIFrameRef2 = (iframeRef: { style: { marginTop: string; border: string; padding: string; height: string; width: string; }; }) => {
        // iframeRef.style.marginTop = "10px"
        iframeRef.style.border = '1px solid #999999';
        iframeRef.style.height = '100%';
        iframeRef.style.width = '100%';
    };

    return (
        <>
            <JitsiMeeting
                domain={'flashcollab.imago.us'}
                roomName={roomId}
                getIFrameRef={handleJitsiIFrameRef2} configOverwrite={configOverwrite} userInfo={userInfo}/>
        </>
    );
};


export default FlashCollab;
