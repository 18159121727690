import "./AiChat.scss";
import React, { useEffect, useState } from "react";
import { Message, Ollama } from "ollama/dist/browser";
//import ollama from 'ollama/dist/browser';
import { marked } from "marked";
import { Button, Input, List, Space, message, notification } from "antd";
import {
  AiSearchIcon,
  CloseIcon,
  DuplicateIcon,
  IconClose,
  IconCloseWhite,
  IconCopy,
  IconUnion,
  PencilIcon,
  ProfileIcon,
  ResetIcon,
} from "./icons";
import { KEYS } from "../keys";
import { debounce, delay, invert, reverse, throttle } from "lodash";
import { nanoid } from "nanoid";
import { AppState, StickyNote } from "../types";
import { t } from "../i18n";
import Spinner from "./Spinner";
import { DraggablePanel } from "./DraggablePanel";
import { IconTrash, IconZoomOut } from "./newIcons";
import { StickyNoteBackground } from "./StickyNotePanel";

export const AiChat: React.FC<{
  appState: AppState;
  setAppState?: React.Component<any, AppState>["setState"];
}> = ({ appState, setAppState }) => {
  const [messages, setMessages] = useState<
    (Message & { id: string; createdAt: Date })[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState("");
  // const [stickyNotes,setStickyNotes] = useState<any[]>([])
  const chatContentRef = React.useRef<HTMLDivElement>(null);
  const renderer = new marked.Renderer();
  const ollama = new Ollama({
    fetch: (url, init) =>
      fetch("/api/v1/ai/chat", {
        ...init,
        method: "POST",
        headers: new Headers({
          Authorization: `Bearer ${appState.userInfo?.authorization}`,
        }),
      }),
  });

  // For code blocks with simple backticks
  renderer.codespan = (code) => {
    return `<code>${code.replaceAll("&amp;", "&")}</code>`;
  };
  useEffect(() => {
    chatContentRef.current!.scrollTop = chatContentRef.current!.scrollHeight;
  });
  async function aiChat(): Promise<void> {
    if (!appState.userLicence?.aIText) {
      alert(t("alerts.shouldGrade"));
      return;
    }
    const assistantMsgId = nanoid();
    let newMessages = [
      ...messages,
      { id: nanoid(), role: "user", content: prompt, createdAt: new Date() },
      {
        id: assistantMsgId,
        role: "assistant",
        content: "",
        createdAt: new Date(),
      },
    ];
    setPrompt("");
    setMessages(newMessages);
    setLoading(true);

    ollama
      .chat({
        model: "llama3",
        messages: newMessages,
        stream: true,
      })
      .then(async (stream) => {
        setLoading(false);
        const content = "";
        let i = 0;
        for await (const chunk of stream) {
          if ((chunk as any).code == 404) {
            const msg = (chunk as any).msg;
            chunk.message = { role: "assistant", content: msg };
            alert(msg);
          }
          // console.log(chunk)
          delay(() => {
            newMessages = newMessages.map((m) => {
              if (m.id == assistantMsgId) {
                if (chunk.message.content != undefined) {
                  m.content += chunk.message.content;
                }
              }

              return m;
            });
            setMessages(newMessages);
          }, 50 * i);
          // setMessages(newMessages);
          // delay(() => {
          //     content+=chunk.message.content;
          //     document.getElementById(assistantMsgId)!.innerHTML =  marked.parse(content) as string;
          //     chatContentRef.current!.scrollTop = chatContentRef.current!.scrollHeight;
          // }, 50*i);
          i++;
        }

        // setMessages(newMessages);
      });
  }

  const removeMessage = (id: string) => {
    setMessages(messages.filter((s) => s.id != id));
  };
  return (
    <div className="ai-chat">
      <div className="body" ref={chatContentRef}>
        <List
          itemLayout="vertical"
          dataSource={messages}
          size="small"
          split={false}
          renderItem={(item, i) => (
            <List.Item key={i}>
              <>
                {/* <List.Item.Meta                    
                    avatar={ProfileIcon} 
                    title={item.role=="user"?"YOU":"IMAGOAI"}
                    description={new Date(item.createdAt).toLocaleString()}
                    /> */}
                <div>
                  <b>{item.role == "user" ? "YOU" : "IMAGO AI"}</b>
                </div>
                {!item.content ? (
                  <div style={{ height: 40 }}>
                    <Spinner />
                  </div> //<div id={item.id} dangerouslySetInnerHTML={{__html:marked.parse(item.content,{gfm: true,breaks: true,renderer}) as string}}></div>
                ) : (
                  <div id={item.id}>
                    {/* {!item.content && <div id="" style={{minHeight:40}}><Spinner /></div>}
                            {item.role=="user"?item.content:""} */}
                    {marked.lexer(item.content).map((t) => {
                      return (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: marked.parse(t.raw, {
                              ...marked.getDefaults(),
                              gfm: true,
                              breaks: true,
                              renderer,
                            }) as string,
                          }}
                        ></div>
                      );
                    })}
                  </div>
                )}
                <div className="item-actions">
                  <a
                    href={undefined}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(item.content)
                        .then(() => {
                          console.log("Text copied to clipboard");
                          message.info("Text copied to clipboard");
                        })
                        .catch((err) => {
                          console.error("Failed to copy text:", err);
                          message.error("Failed to copy text");
                        });
                    }}
                  >
                    {IconCopy}
                  </a>

                  {item.role !== "user" && (
                    <a
                      href={undefined}
                      onClick={() => {
                        if (window.AndroidInterface) {
                          const message = JSON.stringify({
                            event: "stickNotes",
                            user: { content: messages[i - 1].content },
                            assistant: { content: item.content },
                          });
                          window.AndroidInterface.sendMessage(message);
                        } else if (setAppState) {
                          console.log([messages[i - 1], item]);
                          // setStickyNotes([...stickyNotes,{user:messages[i-1],assistant:item}]);
                          const randomBackground = Math.floor(
                            (Math.random() * 10) / 2,
                          );
                          const stickyContent = marked
                            .lexer(item.content)
                            .map((t) => {
                              return marked.parse(t.raw, {
                                ...marked.getDefaults(),
                                gfm: true,
                                breaks: true,
                                renderer,
                              }) as string;
                            })
                            .join("");
                          setAppState({
                            stickyNotes: [
                              ...appState.stickyNotes,
                              {
                                id: nanoid(),
                                key: nanoid(),
                                status: "expand",
                                content: `<h4>YOU:</h4><p>${
                                  messages[i - 1].content
                                }</p><h4>AI:</h4><p>${stickyContent}</p>`,
                                background:
                                  StickyNoteBackground[randomBackground],
                                fontStyle: "normal",
                                textDecoration: "none",
                                fontWeigth: "normal",
                                position: null,
                              },
                            ],
                          });
                        }
                      }}
                    >
                      {IconUnion}
                    </a>
                  )}

                  <a
                    href={undefined}
                    onClick={() => {
                      removeMessage(item.id);
                    }}
                  >
                    {IconClose}
                  </a>
                </div>
              </>
            </List.Item>
          )}
        />
      </div>

      <div className="header">
        <Input
          placeholder="chat with AI"
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          onKeyDown={(e) => {
            e.key == KEYS.ENTER && aiChat();
          }}
        />
        <Button
          onClick={aiChat}
          icon={AiSearchIcon}
          style={{ background: "none" }}
        />
      </div>
    </div>
  );
};
