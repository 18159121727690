import React, { useState, useEffect } from "react";
import QRCode from "qrcode.react";
import socketIOClient from "socket.io-client";
import { NonDeletedImagoElement } from "../element/types";
import { t } from "../i18n";

import { AppState, ExportOpts, BinaryFiles } from "../types";
import { Dialog } from "./Dialog";
import { QrCodeIcon } from "./icons";
import { ToolButton } from "./ToolButton";
import { actionSaveFileToDisk } from "../actions/actionExport";
import { Card } from "./Card";

import "./ExportDialog.scss";
import { nativeFileSystemSupported } from "../data/filesystem";
import { trackEvent } from "../analytics";
import { ActionManager } from "../actions/manager";
import { getFrame } from "../utils";
import MenuItem from "./MenuItem";

export type ExportCB = (
  elements: readonly NonDeletedImagoElement[],
  scale?: number,
) => void;

const QrcodeUploadModal = ({
  elements,
  appState,
  files,
  actionManager,
  exportOpts,
  canvas,
  scanUploadImage,
  modalIsShown,
  setModalIsShown,
}: {
  appState: AppState;
  files: BinaryFiles;
  elements: readonly NonDeletedImagoElement[];
  actionManager: ActionManager;
  onCloseRequest: () => void;
  exportOpts: ExportOpts;
  canvas: HTMLCanvasElement | null;
  modalIsShown: boolean;
  setModalIsShown: React.Dispatch<React.SetStateAction<boolean>>;
  scanUploadImage: (
    data: { insertOnCanvasDirectly: boolean },
    imageFile: File,
  ) => void;
}) => {
  const [socket, setSocket] = useState<SocketIOClient.Socket | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(null);
  const [clientId, setClientId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const handleQRCodeLoad = () => {
    setIsLoading(false);
  };


  useEffect(() => {
    const newSocket = socketIOClient(`${process.env.REACT_APP_LIBRARY_URL}`);
    setSocket(newSocket);

    newSocket.on("connect", () => {
      const client_id = newSocket.id;
      setClientId(client_id);
    });

    newSocket.on("image-uploaded", async (data: any) => {
      const imageUrl = data.imageUrl;
      const url = process.env.REACT_APP_LIBRARY_URL + "/" + imageUrl;
      setUploadedImageUrl(url);
      // setModalIsShown(false);
    });

    return () => {
      newSocket.close();
    };
  }, []);

  const generateImageFile = async (url: any) => {
    const res = await fetch(url);
    const imageData = await res.blob();
    const fileName = url.substring(url.lastIndexOf("/") + 1);
    const file = new File([imageData], fileName, { type: imageData.type });
    return file;
  };

  const loadImageToCanvas = async () => {
    const imgFile = await generateImageFile(uploadedImageUrl);
    scanUploadImage({ insertOnCanvasDirectly: true }, imgFile);
    setModalIsShown(false);
  };

  const cancleLoadImage = () => {
    setUploadedImageUrl(null);
  };

  return (
    <div className="ExportDialog ExportDialog--json">
      <div className="ExportDialog-cards">
        {clientId ? (
          <Card color="lime">

            {uploadedImageUrl ? (
              <div>
                <img style={{ maxWidth: "100%", height: "auto" }} src={`${uploadedImageUrl}`} alt="Uploaded" />
                <div
                  style={{
                    display: "flex",
                    gap: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => loadImageToCanvas()}
                  >
                    {t("buttons.confirm")}
                  </a>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() => cancleLoadImage()}
                  >
                    {t("buttons.cancel")}
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <QRCode value={`imago_${clientId}_${process.env.REACT_APP_LIBRARY_URL}`} onLoad={handleQRCodeLoad} />
              </div>
            )}
          </Card>
         ) : null}
         {/* (<div>Loading...</div>)} */}
      </div>
    </div>
  );
};

export const QrcodeUploadDialog = ({
  elements,
  appState,
  files,
  actionManager,
  exportOpts,
  canvas,
  scanUploadImage,
}: {
  elements: readonly NonDeletedImagoElement[];
  appState: AppState;
  files: BinaryFiles;
  actionManager: ActionManager;
  exportOpts: ExportOpts;
  canvas: HTMLCanvasElement | null;
  scanUploadImage: (
    data: { insertOnCanvasDirectly: boolean },
    imageFile: File,
  ) => void;
}) => {
  const [modalIsShown, setModalIsShown] = useState(false);

  const handleClose = React.useCallback(() => {
    setModalIsShown(false);
  }, []);

  return (
    <>
      <MenuItem
        icon={QrCodeIcon}
        label={t("buttons.qrCode")}
        onClick={() => {
          setModalIsShown(true);
        }}
        dataTestId="json-export-button"
      />
      {modalIsShown && (
        <Dialog small={true} onCloseRequest={handleClose} title={t("buttons.qrCode")}>
          <QrcodeUploadModal
            elements={elements}
            appState={appState}
            files={files}
            modalIsShown={modalIsShown}
            setModalIsShown={setModalIsShown}
            actionManager={actionManager}
            onCloseRequest={handleClose}
            exportOpts={exportOpts}
            canvas={canvas}
            scanUploadImage={scanUploadImage}
          />
        </Dialog>
      )
      }
    </>
  );
};
