import { useEffect, useState } from "react";
import { useDevice } from "./App";
import { AppState } from "../types";
import { GetLoginedUser } from "../utils";
import { IconLogoCollab } from "./newIcons";
import { Cascader, CascaderProps, Tag } from "antd";
import { Popover } from "./Popover";
import { AppMenu } from "./AppMenu";
import clsx from "clsx";
import Stack from "./Stack";
import { Section } from "./Section";
import { WebEmbeds } from "./WebEmbed";
import { Profile } from "./Profile";
import { Pagination } from "./Pagination";
import { Zoom } from "./Zoom";
import { ActionManager } from "../actions/manager";
import React from "react";
import { t } from "../i18n";


export const AppFooter: React.FC<{
  appState: AppState;
  actionManager: ActionManager;
  setAppState: React.Component<any, AppState>["setState"];
  onLogoutDelete: () => void;
  onLogoutSave: () => void;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
  onViewBackgroundColorChange?: (color: string) => void
}> = ({ appState, actionManager, setAppState, onLogoutDelete, onLogoutSave, operaPage, onViewBackgroundColorChange }) => {
  const [showAppMenu, setShowAppMenu] = useState<boolean>(false);
  const footerRef = React.useRef<HTMLDivElement>(null);
  const popRef = React.useRef<HTMLDivElement>(null);
  const device = useDevice();

  const user = GetLoginedUser();


  useEffect(() => {
    // const handler = (event: PointerEvent) => {

    //   if (!device.isMobile && footerRef.current?.contains(event.target as Node)) {
    //     showAppMenu && setShowAppMenu(false);
    //   }
    // };
    // document.addEventListener("pointerdown", handler, false);
    // return () => document.removeEventListener("pointerdown", handler, false);
  })

  return <div className="App-footer" ref={footerRef}>
    <div className="App-system-menu" style={{ zIndex: 1000 }} onClick={(e) => {
      setShowAppMenu(!showAppMenu);
      e.stopPropagation();
    }}>
      {IconLogoCollab}
      <Tag>{t(`pricing.${user?.roleEn.toLowerCase()}`)}{user?.isFreeTrial ? ` - ${t("winTabList.free_trial")}` : ""}</Tag>

    </div>
    {
      showAppMenu && <Popover left={15} bottom={46} zIndex={1000} onCloseRequest={(e) => {
        // e.target !== popRef.current && setShowAppMenu(false);;
        //console.log(pickerButton.current == e.target)

      }} >
        <div ref={popRef} style={{ pointerEvents: "all" }}>
          <AppMenu
            appState={appState}
            actionManager={actionManager}
            operaPage={operaPage}
            setAppState={setAppState}
            onViewBackgroundColorChange={onViewBackgroundColorChange}
            onClick={() => { setShowAppMenu(false); }}
          />
        </div>
      </Popover>
    }
    <div
      className={clsx("layer-ui__wrapper__footer-left zen-mode-transition", {
        "layer-ui__wrapper__footer-left--transition-left":
          appState.zenModeEnabled,
      })}
    >
      <Stack.Col style={{ zIndex: 1000 }}>
        <Section heading="canvasActions" className="left-section">




          <Profile
            onDelete={onLogoutDelete}
            onSave={onLogoutSave}
            appState={appState}
            setAppState={setAppState}
            operaPage={operaPage}
            actionManager={actionManager}
          />
          {/* <Pagination
            appState={appState}
            actionManager={actionManager}
            setAppState={setAppState}
          /> */}

          {/* <div className="App-toolbar__divider" style={{ margin: 0 }}></div>
          <Zoom
            appState={appState}
            actionManager={actionManager}
            setAppState={setAppState}
          /> */}
          {/* <ZoomActions
              renderAction={actionManager.renderAction}
              zoom={appState.zoom}
            /> */}
        </Section>
        {/*<Island
          padding={1}
          className={clsx("App-toolbar", {
            "zen-mode": appState.zenModeEnabled,
          })}
        >
          
          <Section heading="canvasActions">
            <ZoomActions
              renderAction={actionManager.renderAction}
              zoom={appState.zoom}
            />

            <div className="App-toolbar__divider"></div>

            {!appState.viewModeEnabled && (
              <UndoRedoActions
                renderAction={actionManager.renderAction}
                className={clsx("zen-mode-transition", {
                  "layer-ui__wrapper__footer-left--transition-bottom":
                    appState.zenModeEnabled,
                })}
              />
            )}
            {showFinalize && (
              <FinalizeAction
                renderAction={actionManager.renderAction}
                className={clsx("zen-mode-transition", {
                  "layer-ui__wrapper__footer-left--transition-left":
                    appState.zenModeEnabled,
                })}
              />
            )}
          </Section> 
        </Island>*/}
      </Stack.Col>
    </div>

  </div>
}