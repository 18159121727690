import polyfill from "../polyfill";
import LanguageDetector from "i18next-browser-languagedetector";
import { useEffect, useRef, useState } from "react";
import { trackEvent } from "../analytics";
import { getDefaultAppState } from "../appState";
import { ErrorDialog } from "../components/ErrorDialog";
import { TopErrorBoundary } from "../components/TopErrorBoundary";
import {
  APP_NAME,
  AUTO_SAVE_SCENE_TIMEOUT,
  COOKIES,
  EVENT,
  THEME,
  TITLE_TIMEOUT,
  VERSION_TIMEOUT,
  API_URL,
} from "../constants";
import { blobToArrayBuffer, loadFromBlob, loadFromBlobPage } from "../data/blob";
import {
  ImagoElement,
  FileId,
  NonDeletedImagoElement,
  Theme,
} from "../element/types";
import { useCallbackRefState } from "../hooks/useCallbackRefState";
import { t } from "../i18n";
import {
  Imago,
  defaultLang,
  viewportCoordsToSceneCoords,
} from "../packages/imago/index";
import {
  AppState,
  LibraryItems,
  ImagoImperativeAPI,
  BinaryFiles,
  ImagoInitialDataState,
  BinaryFileData,
  LibraryItem,
  Page,
  NormalizedZoomValue,
} from "../types";
import {
  debounce,
  getVersion,
  getFrame,
  isTestEnv,
  preventUnload,
  ResolvablePromise,
  resolvablePromise,
  distance,
  GetLoginedUser,
} from "../utils";
import {
  FIREBASE_STORAGE_PREFIXES,
  STORAGE_KEYS,
  SYNC_BROWSER_TABS_TIMEOUT,
} from "./app_constants";
import Collab, {
  CollabAPI,
  collabAPIAtom,
  collabDialogShownAtom,
  googleDriveDialogShownAtom,
  isCollaboratingAtom,
  loadFromUserSceneFlagAtom,
} from "./collab/Collab";
import { LanguageList } from "./components/LanguageList";
import {
  exportToBackend,
  getCollaborationLinkData,
  isCollaborationLink,
  loadScene,
} from "./data";
import { getCurrPageFromStorage, importUsernameFromLocalStorage } from "./data/localStorage";
import { getLibraryItemsFromStorage, importFromIndexDB } from "./data/storage";
import CustomStats from "./CustomStats";
import {
  restore,
  restoreAppState,
  RestoredDataState,
  restoreElements,
} from "../data/restore";

import "./index.scss";
import { ExportToImagoPlus } from "./components/ExportToImagoPlus";

import { updateStaleImageStatuses } from "./data/FileManager";
import { newElementWith } from "../element/mutateElement";
import { isInitializedImageElement } from "../element/typeChecks";
import { loadFilesFromFirebase } from "./data/firebase";
import { isBrowserStorageStateNewer } from "./data/tabSync";
import clsx from "clsx";
import { atom, Provider, useAtom } from "jotai";
import { jotaiStore, useAtomWithInitialValue } from "../jotai";
import { reconcileElements } from "./collab/reconciliation";
import { parseLibraryTokensFromUrl, useHandleLibrary } from "../data/library";
import { LocalData } from "./data/LocalData";
import { getCommonBounds } from "../element";
import { getGridPoint } from "../math";
import Palza from "./Plaza";
import { getNormalizedZoom } from "../scene";
import { PageManager } from "./data/PageManager";
import { zoomToFitBounds } from "../actions/actionCanvas";
import { Zoom } from "../components/Zoom";
import { inflate } from "pako";

polyfill();
window.IMAGO_THROTTLE_RENDER = true;

const isImagoPlusSignedUser = document.cookie.includes(
  COOKIES.AUTH_STATE_COOKIE,
);

export const languageDetector = new LanguageDetector();
languageDetector.init({
  languageUtils: {},
});

const changeInitializeScene = async (opts: {
  collabAPI: CollabAPI;
  imagoAPI: ImagoImperativeAPI;
}): Promise<
  { scene: ImagoInitialDataState | null } & (
    | { isExternalScene: true; id: string; key: string }
    | { isExternalScene: false; id?: null; key?: null }
  )
> => {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const jsonBackendMatch = window.location.hash.match(
    /^#json=([a-zA-Z0-9_-]+),([a-zA-Z0-9_-]+)$/,
  );
  const externalUrlMatch = window.location.hash.match(/^#url=(.*)$/);

  const localDataState = await importFromIndexDB();

  const scene: RestoredDataState & {
    scrollToContent?: boolean;
  } = await loadScene(null, null, localDataState);

  if (scene) {
    return opts.collabAPI.isCollaborating() && jsonBackendMatch
      ? {
        scene,
        isExternalScene: true,
        id: jsonBackendMatch[1],
        key: jsonBackendMatch[2],
      }
      : { scene, isExternalScene: false };
  }
  return { scene, isExternalScene: false };
};

const initializeScene = async (opts: {
  collabAPI: CollabAPI;
  imagoAPI: ImagoImperativeAPI;
}): Promise<
  { scene: ImagoInitialDataState | null } & (
    | { isExternalScene: true; id: string; key: string }
    | { isExternalScene: false; id?: null; key?: null }
  )
> => {
  const searchParams = new URLSearchParams(window.location.search);
  const id = searchParams.get("id");
  const tpl = searchParams.get("tpl");
  const jsonBackendMatch = window.location.hash.match(
    /^#json=([a-zA-Z0-9_-]+),([a-zA-Z0-9_-]+)$/,
  );
  const externalUrlMatch = window.location.hash.match(/^#url=(.*)$/);

  const localDataState = await importFromIndexDB();

  let scene: RestoredDataState & {
    scrollToContent?: boolean;
  } = await loadScene(null, null, localDataState);

  let roomLinkData = await getCollaborationLinkData(
    window.location.href,
    opts.imagoAPI.getAppState(),
  );

  const isExternalScene = !!(id || jsonBackendMatch || roomLinkData);
  if (isExternalScene) {
    if (
      // don't prompt if scene is empty
      !scene.elements.length ||
      // don't prompt for collab scenes because we don't override local storage
      roomLinkData ||
      // otherwise, prompt whether user wants to override current scene
      window.confirm(t("alerts.loadSceneOverridePrompt"))
    ) {
      if (jsonBackendMatch) {
        scene = await loadScene(
          jsonBackendMatch[1],
          jsonBackendMatch[2],
          localDataState,
        );
      }
      scene.scrollToContent = true;
      if (!roomLinkData) {
        window.history.replaceState({}, APP_NAME, window.location.origin);
      }
    } else {
      // https://github.com/imago/imago/issues/1919
      if (document.hidden) {
        return new Promise((resolve, reject) => {
          window.addEventListener(
            "focus",
            () => initializeScene(opts).then(resolve).catch(reject),
            {
              once: true,
            },
          );
        });
      }

      roomLinkData = null;
      window.history.replaceState({}, APP_NAME, window.location.origin);
    }
  } else if (externalUrlMatch) {
    window.history.replaceState({}, APP_NAME, window.location.origin);

    const url = externalUrlMatch[1];
    try {
      const request = await fetch(window.decodeURIComponent(url));
      const data = await loadFromBlob(await request.blob(), null, null);
      if (
        !scene.elements.length ||
        window.confirm(t("alerts.loadSceneOverridePrompt"))
      ) {
        return { scene: data, isExternalScene };
      }
    } catch (error: any) {
      return {
        scene: {
          appState: {
            errorMessage: t("alerts.invalidSceneUrl"),
          },
        },
        isExternalScene,
      };
    }
  } else if (tpl) {
    try {
      const request = await fetch(`${API_URL.dowloadTemplate}?path=${tpl}`, {
        headers: {
          Authorization: `Bearer ${scene.appState.userInfo?.authorization}`,
        },
      });

      if (
        !scene.elements.length
        // ||
        // window.confirm(t("alerts.loadSceneOverridePrompt"))
      ) {
        //const data = await loadFromBlob(await request.blob(), null, null);

        const data = await loadFromBlobPage(await request.blob(), null);

        return { scene: data!, isExternalScene };
      }
    } catch (error: any) {
      return {
        scene: {
          appState: {
            errorMessage: t("alerts.invalidSceneUrl"),
          },
        },
        isExternalScene,
      };
    }
  } else if (searchParams.has("recently-edit")) {

    await opts.collabAPI.loadUserSceneData();
  }

  if (roomLinkData) {
    const { imagoAPI } = opts;

    const scene = await opts.collabAPI.startCollaboration(roomLinkData);

    if (roomLinkData.googleMeetingCode) {
      opts.collabAPI.setIsGoogleMeeting(roomLinkData.googleMeetingCode);
      imagoAPI.escalateGoogleMeet(true);
    }

    let scrollX = 0, scrollY = 0;
    let zoom = 1;
    if (scene?.elements && scene?.elements?.length > 0) {
      const fit = zoomToFitBounds({
        bounds: getCommonBounds(scene?.elements || []),
        appState: imagoAPI.getAppState(),
        fitToViewport: false,
      });
      scrollX = fit.appState.scrollX;
      scrollY = fit.appState.scrollY;
      zoom = fit.appState.zoom.value;
    } else if (roomLinkData.extraInfo.screenWidth > 0) {
      zoom = window.innerWidth / roomLinkData.extraInfo.screenWidth;
      console.log(zoom)
    }
    return {
      // when collaborating, the state may have already been updated at this
      // point (we may have received updates from other clients), so reconcile
      // elements and appState with existing state
      scene: {
        ...scene,
        appState: {
          ...restoreAppState(
            {
              ...scene?.appState,
              theme: localDataState?.appState?.theme || scene?.appState?.theme,
            },
            imagoAPI.getAppState(),

          ),
          // necessary if we're invoking from a hashchange handler which doesn't
          // go through App.initializeScene() that resets this flag
          isLoading: false,
          showFlashCollabration: true,
          scrollX,
          scrollY,
          zoom: { value: zoom as NormalizedZoomValue }
        },
        elements: reconcileElements(
          scene?.elements || [],
          imagoAPI.getSceneElementsIncludingDeleted(),
          imagoAPI.getAppState(),
        ),

      },
      isExternalScene: true,
      id: roomLinkData.roomId,
      key: roomLinkData.roomKey,
    };
  } else if (scene) {
    return isExternalScene && jsonBackendMatch
      ? {
        scene,
        isExternalScene,
        id: jsonBackendMatch[1],
        key: jsonBackendMatch[2],
      }
      : { scene, isExternalScene: false };
  }

  return { scene: null, isExternalScene: false };
};

const currentLangCode = languageDetector.detect() || defaultLang.code;

export const langCodeAtom = atom(
  Array.isArray(currentLangCode) ? currentLangCode[0] : currentLangCode,
);

interface ImagoWrapperProps {
  // toPage: string,
  // actionFlag: string,
  // operaPage?: ({ page, actionName }: { actionName?: string, page: string }) => void,
  // pageList?: string[],
  // currPage?: string,
  // appState?: AppState,
  // setAppState?: React.Component<any, AppState>["setState"],
}

const ImagoWrapper = ({ }: ImagoWrapperProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [langCode, setLangCode] = useAtom(langCodeAtom);

  // const [actionFlag, setActionFlag] = useState("");
  // const [actionNameFlag, setActionNameFlag] = useState("");
  // const [pageList, setPageList] = useState(getPageListFromStorage());
  // const [currPage, setCurrPage] = useState(getCurrPageFromStorage());
  const [loadFlag, setLoadFlag] = useState("");

  // const operaPage = async ({ page, actionName }: { actionName?: string, page: string }) => {

  //   if (collabAPI?.isCollaborating()) {
  //     collabAPI.syncChangePage({ toPage: page, actionNameFlag: actionName })
  //   } else {
  //     setCurrPage(getCurrPageFromStorage())
  //     setPageList(getPageListFromStorage());
  //   }
  //   operaPageAction({ page, actionName })
  // }

  // const operaPageAction = async ({ page, actionName }: { actionName?: string, page: string }) => {

  //   var timestamp = new Date().getTime().toString();
  //   let flag = actionName + "_" + timestamp
  //   actionName && setActionNameFlag(actionName)

  //   if (actionName === "remove") {
  //     const updatedPageList = pageList.filter(pageItem => pageItem.id !== page);
  //     setPageList(updatedPageList);
  //     setPageListToStorage(updatedPageList)
  //     delDataFromStorage(page)

  //     if (currPage == page) {
  //       const newCurrPage = updatedPageList && updatedPageList.length > 0 ? updatedPageList[0] : {id:"page_0",name:"page_0"}
  //       setCurrPage(newCurrPage.id)
  //       setCurrPageToStorage(newCurrPage.id)
  //     }
  //     actionFlag && await setActionFlag(flag)

  //   } else {

  //     setCurrPage(page)
  //     setCurrPageToStorage(page)
  //     if (actionName === "add") {
  //       const newPageList = [...pageList, PageManager.addPage({})];
  //       setPageListToStorage(newPageList);
  //       setPageList(newPageList)
  //     } else if (actionName === 'load') {
  //       setLoadFlag(flag)
  //     }

  //     actionFlag && await setActionFlag(flag)
  //   }
  // }

  // const [toSyncPage, setToSyncPage] = useAtom(toPageAtom);
  // const [syncFlag, setSyncFlag] = useAtom(syncFlagAtom);
  // const [loadFromRemoteFlag, setLoadFromRemoteFlag] = useAtom(loadFromRemoteFlagAtom);
  // const [syncActionNameFlag, setSyncActionNameFlag] = useAtom(syncActionNameFlagAtom);

  // useEffect(() => {

  //   const syncOper = async () => {
  //     await operaPageAction({ page: toSyncPage, actionName: syncActionNameFlag })
  //     collabAPI && collabAPI.syncChangeFinished({ toPage: toSyncPage })
  //   }
  //   syncOper()
  //   setPageList(getPageListFromStorage())
  //   setCurrPage(getCurrPageFromStorage())

  // }, [syncFlag]);

  // useEffect(() => {
  //   setPageList(getPageListFromStorage())
  //   setCurrPage(getCurrPageFromStorage())

  // }, [loadFromRemoteFlag]);

  // initial state
  // ---------------------------------------------------------------------------

  const initialStatePromiseRef = useRef<{
    promise: ResolvablePromise<ImagoInitialDataState | null>;
  }>({ promise: null! });
  if (!initialStatePromiseRef.current.promise) {
    initialStatePromiseRef.current.promise =
      resolvablePromise<ImagoInitialDataState | null>();
  }

  const [localDataState, setLocalDataState] = useState<any>();

  const loadImages = (
    data: ResolutionType<typeof initializeScene>,
    isInitialLoad = false,
  ) => {
    if (!data.scene) {
      return;
    }

    if (!collabAPI || !imagoAPI) {
      return;
    }

    if (collabAPI.isCollaborating()) {
      if (data.scene.elements) {
        collabAPI
          .fetchImageFilesFromFirebase({
            elements: data.scene.elements,
            forceFetchFiles: true,
          })
          .then(({ loadedFiles, erroredFiles }) => {
            imagoAPI.addFiles(loadedFiles);
            updateStaleImageStatuses({
              imagoAPI,
              erroredFiles,
              elements: imagoAPI.getSceneElementsIncludingDeleted(),
            });
          });
      }
    } else {
      const fileIds =
        data.scene.elements?.reduce((acc, element) => {
          if (isInitializedImageElement(element)) {
            return acc.concat(element.fileId);
          }
          return acc;
        }, [] as FileId[]) || [];

      if (data.isExternalScene) {
        loadFilesFromFirebase(
          `${FIREBASE_STORAGE_PREFIXES.shareLinkFiles}/${data.id}`,
          data.key,
          fileIds,
        ).then(({ loadedFiles, erroredFiles }) => {
          imagoAPI.addFiles(loadedFiles);
          updateStaleImageStatuses({
            imagoAPI,
            erroredFiles,
            elements: imagoAPI.getSceneElementsIncludingDeleted(),
          });
        });
      } else if (isInitialLoad) {
        if (fileIds.length) {
          LocalData.fileStorage
            .getFiles(fileIds)
            .then(({ loadedFiles, erroredFiles }) => {
              if (loadedFiles.length) {
                imagoAPI.addFiles(loadedFiles);
              }
              updateStaleImageStatuses({
                imagoAPI,
                erroredFiles,
                elements: imagoAPI.getSceneElementsIncludingDeleted(),
              });
            });
        }
        // on fresh load, clear unused files from IDB (from previous
        // session)
        LocalData.fileStorage.clearObsoleteFiles({ currentFileIds: fileIds });
      }
    }
  };

  useEffect(() => {
    trackEvent("load", "frame", getFrame());
    // Delayed so that the app has a time to load the latest SW
    setTimeout(() => {
      trackEvent("load", "version", getVersion());
    }, VERSION_TIMEOUT);
  }, []);

  const [imagoAPI, imagoRefCallback] =
    useCallbackRefState<ImagoImperativeAPI>();

  const [collabAPI] = useAtom(collabAPIAtom);
  const [, setCollabDialogShown] = useAtom(collabDialogShownAtom);
  const [, setGoogleDriveDialogShown] = useAtom(googleDriveDialogShownAtom);
  // const [toSyncPage, setToSyncPage] = useAtom(toPageAtom);
  const [isCollaborating] = useAtomWithInitialValue(isCollaboratingAtom, () => {
    return isCollaborationLink(window.location.href);
  });

  setInterval(() => {
    if (!collabAPI?.isCollaborating()) {
      collabAPI?.saveUserScene();
    }
  }, AUTO_SAVE_SCENE_TIMEOUT);

  const [loadFromUserSceneFlag] = useAtom(loadFromUserSceneFlagAtom);

  // useEffect(() => {
  //   if (currPage === "page_0") {
  //     delDataFromStorage(currPage)
  //     const newPage = PageManager.addPage({})
  //     setCurrPageToStorage(newPage.id)
  //     setPageListToStorage([newPage])
  //   }
  //   imagoAPI?.updateScene({ elements: [] });
  //   setLocalDataState(importFromLocalStorage())
  // }, [currPage]);

  // useEffect(() => {

  //   imagoAPI?.updateScene({ elements: [] });
  //   importFromIndexDB().then(data=>{
  //     setLocalDataState(data);
  //   })

  // }, [loadFlag]);

  // useEffect(() => {
  //   imagoAPI?.updateScene({ elements: [] });
  //   importFromIndexDB().then(data=>{
  //     setLocalDataState(data);
  //   })
  // }, [loadFromUserSceneFlag]);

  useEffect(() => {
    const fetchData = async () => {
      if (collabAPI && imagoAPI) {
        changeInitializeScene({ collabAPI, imagoAPI }).then(async (data) => {
          loadImages(data, /* isInitialLoad */ true);
          // initialStatePromiseRef.current.promise.resolve(data.scene);
          imagoAPI.updateScene({
            ...data.scene,
            ...restore(data.scene, null, null),
            commitToHistory: false,
          });
        });
      }
    };
    fetchData();
  }, [loadFromUserSceneFlag]);

  useHandleLibrary({
    imagoAPI,
    getInitialLibraryItems: getLibraryItemsFromStorage,
  });

  useEffect(() => {
    if (!collabAPI || !imagoAPI) {
      return;
    }

    initializeScene({ collabAPI, imagoAPI }).then(async (data) => {
      loadImages(data, /* isInitialLoad */ true);

      initialStatePromiseRef.current.promise.resolve(data.scene);
    });

    const onHashChange = async (event: HashChangeEvent) => {
      event.preventDefault();
      if (new URLSearchParams(window.location.hash.slice(1)).get("gsc.tab")) {
        return;
      }

      const libraryUrlTokens = parseLibraryTokensFromUrl();

      if (!libraryUrlTokens) {
        if (
          collabAPI.isCollaborating() &&
          !isCollaborationLink(window.location.href)
        ) {
          collabAPI.stopCollaboration(false);
        }
        imagoAPI.updateScene({ appState: { isLoading: true } });

        initializeScene({ collabAPI, imagoAPI }).then((data) => {
          loadImages(data);
          if (data.scene) {
            imagoAPI.updateScene({
              ...data.scene,
              ...restore(data.scene, null, null),
              commitToHistory: true,
            });
          }
        });
      }
    };

    const titleTimeout = setTimeout(
      () => (document.title = APP_NAME),
      TITLE_TIMEOUT,
    );

    const syncData = debounce(async () => {
      if (isTestEnv()) {
        return;
      }
      if (!document.hidden && !collabAPI.isCollaborating()) {
        // don't sync if local state is newer or identical to browser state
        if (isBrowserStorageStateNewer(STORAGE_KEYS.VERSION_DATA_STATE)) {
          const localDataState = await importFromIndexDB();

          const username = importUsernameFromLocalStorage();
          let langCode = languageDetector.detect() || defaultLang.code;
          if (Array.isArray(langCode)) {
            langCode = langCode[0];
          }
          setLangCode(langCode);
          imagoAPI.updateScene({
            ...localDataState,
          });

          imagoAPI.updateLibrary({
            libraryItems: await getLibraryItemsFromStorage(),
          });
          collabAPI.setUsername(username || "");
        }

        if (isBrowserStorageStateNewer(STORAGE_KEYS.VERSION_FILES)) {
          const elements = imagoAPI.getSceneElementsIncludingDeleted();
          const currFiles = imagoAPI.getFiles();
          const fileIds =
            elements?.reduce((acc, element) => {
              if (
                isInitializedImageElement(element) &&
                // only load and update images that aren't already loaded
                !currFiles[element.fileId]
              ) {
                return acc.concat(element.fileId);
              }
              return acc;
            }, [] as FileId[]) || [];
          if (fileIds.length) {
            LocalData.fileStorage
              .getFiles(fileIds)
              .then(({ loadedFiles, erroredFiles }) => {
                if (loadedFiles.length) {
                  imagoAPI.addFiles(loadedFiles);
                }
                updateStaleImageStatuses({
                  imagoAPI,
                  erroredFiles,
                  elements: imagoAPI.getSceneElementsIncludingDeleted(),
                });
              });
          }
        }
      }
    }, SYNC_BROWSER_TABS_TIMEOUT);

    const onUnload = () => {
      LocalData.flushSave();
    };

    const visibilityChange = (event: FocusEvent | Event) => {
      if (event.type === EVENT.BLUR || document.hidden) {
        LocalData.flushSave();
      }
      if (
        event.type === EVENT.VISIBILITY_CHANGE ||
        event.type === EVENT.FOCUS
      ) {
        syncData();
      }
    };

    window.addEventListener(EVENT.HASHCHANGE, onHashChange, false);
    window.addEventListener(EVENT.UNLOAD, onUnload, false);
    window.addEventListener(EVENT.BLUR, visibilityChange, false);
    document.addEventListener(EVENT.VISIBILITY_CHANGE, visibilityChange, false);
    window.addEventListener(EVENT.FOCUS, visibilityChange, false);
    return () => {
      window.removeEventListener(EVENT.HASHCHANGE, onHashChange, false);
      window.removeEventListener(EVENT.UNLOAD, onUnload, false);
      window.removeEventListener(EVENT.BLUR, visibilityChange, false);
      window.removeEventListener(EVENT.FOCUS, visibilityChange, false);
      document.removeEventListener(
        EVENT.VISIBILITY_CHANGE,
        visibilityChange,
        false,
      );
      clearTimeout(titleTimeout);
    };
  }, [collabAPI, imagoAPI, setLangCode]);

  useEffect(() => {
    const unloadHandler = (event: BeforeUnloadEvent) => {
      LocalData.flushSave();

      if (
        imagoAPI &&
        LocalData.fileStorage.shouldPreventUnload(imagoAPI.getSceneElements())
      ) {
        preventUnload(event);
      }
    };
    window.addEventListener(EVENT.BEFORE_UNLOAD, unloadHandler);
    return () => {
      window.removeEventListener(EVENT.BEFORE_UNLOAD, unloadHandler);
    };
  }, [imagoAPI]);

  useEffect(() => {
    languageDetector.cacheUserLanguage(langCode);
  }, [langCode]);

  const [theme, setTheme] = useState<Theme>(
    () =>
      localStorage.getItem(STORAGE_KEYS.LOCAL_STORAGE_THEME) ||
      // FIXME migration from old LS scheme. Can be removed later. #5660
      //importFromLocalStorage().appState?.theme ||
      THEME.LIGHT,
  );

  useEffect(() => {
    localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_THEME, theme);
    // currently only used for body styling during init (see public/index.html),
    // but may change in the future
    document.documentElement.classList.toggle("dark", theme === THEME.DARK);
  }, [theme]);

  const onChange = async (
    elements: readonly ImagoElement[],
    appState: AppState,
    setAppState: React.Component<any, AppState>["setState"],
    files: BinaryFiles,
    deletedFiles: BinaryFiles,
  ) => {
    if (collabAPI?.isCollaborating()) {
      collabAPI.syncElements(elements);
      if (deletedFiles) {
        collabAPI?.deleteFilesFromCollabData(deletedFiles);
      }
    }

    setTheme(appState.theme);

    // this check is redundant, but since this is a hot path, it's best
    // not to evaludate the nested expression every time
    // if (!LocalData.isSavePaused()) {

    LocalData.save(elements, appState, files, () => {
      if (imagoAPI) {
        let didChange = false;
        const elements = imagoAPI
          .getSceneElementsIncludingDeleted()
          .map((element) => {
            if (LocalData.fileStorage.shouldUpdateImageElementStatus(element)) {
              const newElement = newElementWith(element, { status: "saved" });
              if (newElement !== element) {
                didChange = true;
              }
              return newElement;
            }
            return element;
          });

        if (didChange) {
          imagoAPI.updateScene({
            elements,
          });
        }
      }
    });
    // }
  };

  const onFilesAdd = (files: BinaryFileData[]) => {
    if (collabAPI?.isCollaborating()) {
      collabAPI.syncFiles(files);
    }
  };

  const onViewBackgroundColorChange = (color: string) => {
    const pageId = getCurrPageFromStorage();
    const page = PageManager.getPage(pageId);
    page.backgroundColor = color;
    PageManager.editPage(page);
    if (collabAPI?.isCollaborating()) {
      collabAPI.syncViewBackgroundColor({ pageId, backgroundColor: color, gridColor: "" });
    }
  }

  const onExportToBackend = async (
    exportedElements: readonly NonDeletedImagoElement[],
    appState: AppState,
    files: BinaryFiles,
    canvas: HTMLCanvasElement | null,
  ) => {
    if (exportedElements.length === 0) {
      return window.alert(t("alerts.cannotExportEmptyCanvas"));
    }
    if (canvas) {
      try {
        await exportToBackend(
          exportedElements,
          {
            ...appState,
            viewBackgroundColor: appState.exportBackground
              ? appState.viewBackgroundColor
              : getDefaultAppState().viewBackgroundColor,
          },
          files,
        );
      } catch (error: any) {
        if (error.name !== "AbortError") {
          const { width, height } = canvas;
          console.error(error, { width, height });
          setErrorMessage(error.message);
        }
      }
    }
  };

  const renderFooter = (isMobile: boolean) => {
    const renderLanguageList = () => <LanguageList />;
    if (isMobile) {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: ".5rem", fontSize: "0.75rem" }}>
            {t("labels.language")}
          </div>
          <div style={{ padding: "0 0.625rem" }}>{renderLanguageList()}</div>
        </div>
      );
    }

    return (
      <div style={{ display: "flex", gap: ".5rem", alignItems: "center" }}>
        {isImagoPlusSignedUser && (
          <a
            href={`${process.env.REACT_APP_PLUS_APP}?utm_source=imago&utm_medium=app&utm_content=signedInUserRedirectButton#imago-redirect`}
            target="_blank"
            rel="noreferrer"
            className="plus-button"
          >
            Go to Collab
          </a>
        )}
      </div>
    );
  };

  const renderCustomStats = (
    elements: readonly NonDeletedImagoElement[],
    appState: AppState,
  ) => {
    return (
      <CustomStats
        setToast={(message) => imagoAPI!.setToast({ message })}
        appState={appState}
        elements={elements}
      />
    );
  };

  const [initialStatePromiseRef1, setLnitialStatePromiseRef1] = useState(
    initialStatePromiseRef,
  );

  const onLibraryChange = async (items: LibraryItems) => {
    if (!items.length) {
      //localStorage.removeItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY);
      await LocalData.librariesStorage.clear();
      return;
    }
    // const serializedItems = JSON.stringify(items);
    // localStorage.setItem(STORAGE_KEYS.LOCAL_STORAGE_LIBRARY, serializedItems);
    await LocalData.librariesStorage.saveAll(items as LibraryItem[]);
  };

  return (
    <div
      style={{ height: "100%" }}
      className={clsx("imago-app", {
        "is-collaborating": isCollaborating,
      })}
    >
      <Imago
        ref={imagoRefCallback}
        onChange={onChange}
        initialData={initialStatePromiseRef.current.promise}
        onCollabButtonClick={() => setCollabDialogShown(true)}
        onGoogleDriveClick={() => setGoogleDriveDialogShown(true)}
        isCollaborating={isCollaborating}
        onPointerUpdate={collabAPI?.onPointerUpdate}
        // operaPage={operaPage}
        // pageList={pageList?.map(p=>p.id)}
        // currPage={currPage}
        UIOptions={{
          canvasActions: {
            toggleTheme: true,
            export: {
              onExportToBackend,
              renderCustomUI: (elements, appState, files) => {
                return (
                  <ExportToImagoPlus
                    elements={elements}
                    appState={appState}
                    files={files}
                    onError={(error) => {
                      imagoAPI?.updateScene({
                        appState: {
                          errorMessage: error.message,
                        },
                      });
                    }}
                  />
                );
              },
            },
          },
        }}
        renderFooter={renderFooter}
        langCode={langCode}
        renderCustomStats={renderCustomStats}
        detectScroll={false}
        handleKeyboardGlobally={true}
        onLibraryChange={onLibraryChange}
        autoFocus={true}
        theme={theme}
        onFilesAdd={onFilesAdd}
        onViewBackgroundColorChange={onViewBackgroundColorChange}
      />
      {imagoAPI && <Collab imagoAPI={imagoAPI} />}
      {imagoAPI && <Palza imagoAPI={imagoAPI} />}
      {errorMessage && (
        <ErrorDialog
          message={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
    </div>
  );
};

const ImagoApp = () => {
  return (
    <TopErrorBoundary>
      <Provider unstable_createStore={() => jotaiStore}>
        <ImagoWrapper />
      </Provider>
    </TopErrorBoundary>
  );
};

export default ImagoApp;
