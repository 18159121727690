import "./Participants.scss";
import { Dialog } from "./Dialog";
import { AppState, Collaborator } from "../types";
import { Modal } from "./Modal";
import { Popover } from "./Popover";
import { Island } from "./Island";
import { Avatar, Card, List } from "antd";
import { IconDash, IconDial, IconHost, IconNoneColorPlus, IconPlus, IconTrash } from "./newIcons";
import { CloseIcon, FlashCollabIcon, IconCloseWhite, ProfileIcon } from "./icons";
import { useDevice } from "./App";
import { ActionManager } from "../actions/manager";
import { Tooltip } from "./Tooltip";
import React from "react";
import { GetLoginedUser } from "../utils";

export const Participants:React.FC<{appState:AppState, setAppState: React.Component<any, AppState>["setState"];isFlashCollabing:boolean;
    onCallClick: () => void;
    actionManager: ActionManager;
    mobile:boolean;
}> = ({appState,setAppState,isFlashCollabing,onCallClick,
    actionManager,
    mobile
})=>{

    const device = useDevice();
    const uniqueCollaborators = new Map<string, Collaborator>();
    const user = GetLoginedUser();
    appState.collaborators.forEach((collaborator, socketId) => {
      uniqueCollaborators.set(
        // filter on user id, else fall back on unique socketId
        collaborator.id || socketId,
        collaborator,
      );
    });
    

    let userList : JSX.Element[]=[];
    if(uniqueCollaborators.size > 0){
      const uniqueCollaboratorsArray = Array.from(uniqueCollaborators);
      if(uniqueCollaboratorsArray.length>1){
        for(var i=0;i<uniqueCollaboratorsArray.length;i++){
          if(uniqueCollaboratorsArray[i][1].isHost){
            const a = uniqueCollaboratorsArray[0];
            uniqueCollaboratorsArray[0] = uniqueCollaboratorsArray[i];
            uniqueCollaboratorsArray[i] = a;
          }else if(uniqueCollaboratorsArray[i][1].username == user?.username){
            const a = uniqueCollaboratorsArray[1];
            uniqueCollaboratorsArray[1] = uniqueCollaboratorsArray[i];
            uniqueCollaboratorsArray[i] = a;
          }
        }

      }
      userList = uniqueCollaboratorsArray
        .filter(([_, client]) => Object.keys(client).length !== 0)
        .map(([clientId, collaborator]) => {
          const avatarJSX = actionManager.renderAction("goToCollaborator", [
            clientId,
            collaborator,
          ]);
  
          return <div className="participants-content-item"  key={clientId}> {avatarJSX}<span className="user-name">{collaborator.username}</span><>{collaborator.isHost && IconHost}</></div>;
        });
    }

    return <Popover onCloseRequest={()=>{}} left={10} top={200}>
        <div className="participants">
            <div className="participants-header">
                <h4>Participants</h4>
                <a href={undefined} onClick={()=>{setAppState({openDialog:null})}}>{CloseIcon}</a>
            </div>
            <div className="participants-content">
            {userList}
            </div>
      
            <div className="participants-footer">
                {isFlashCollabing?<div className="footer-button" style={{background:"#CC3B33"}} onClick={onCallClick}>
                    {IconDial}
                    <span>End Call</span>
                </div>:
                <div className="footer-button" style={{background:"green"}} onClick={onCallClick}>
                {IconDial}
                <span>Start Call</span>
            </div>}
                
            </div>
        </div>
    </Popover>
}