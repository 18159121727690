import React, { ReactElement, useState } from "react";
import { ActionManager } from "../actions/manager";
import { getNonDeletedElements } from "../element";
import { ImagoElement, NonDeletedImagoElement, PointerType } from "../element/types";
import { t } from "../i18n";
import { useDevice } from "../components/App";
import {
  canChangeSharpness,
  canHaveArrowheads,
  getTargetElements,
  hasBackground,
  hasStrokeStyle,
  hasStrokeWidth,
  hasText,
} from "../scene";
import { SHAPES } from "../shapes";
import { AppState, Zoom } from "../types";
import {
  capitalizeString,
  isTransparent,
  updateActiveTool,
  setCursorForShape,
} from "../utils";
import Stack from "./Stack";
import { ToolButton, ToolFlashCollab } from "./ToolButton";
import { hasStrokeColor } from "../scene/comparisons";
import { trackEvent } from "../analytics";
import { hasBoundTextElement, isBoundToContainer, isTextElement } from "../element/typeChecks";
import clsx from "clsx";
import { actionToggleZenMode } from "../actions";
import "./Actions.scss";
import { Tooltip } from "./Tooltip";
import { Popover } from "./Popover";
import { Island } from "./Island";
import oc from "open-color";
import CollabButton from "./CollabButton";
import { LibraryButton } from "./LibraryButton";
import { DriveIcon, ImageIcon, ImageSearchIcon, MutiObjectsIcon, LoadIcon, GustureIcon, PageListIcon, ImageGalleryIcon, ImageAiIcon, CloudServiceIcon } from "../components/icons";
import { MultiObjectsAction } from "./MultiObjectsAction";
import { loadFromJSONPage } from "../data/json";
import { saveAsJSONPage } from "../data";
import { TOOLBAR_GROUPS } from "../constants";
import { Handwriting } from "../imago-app/Handwriting";
import { nanoid } from "nanoid";
import { StickyNoteBackground } from "./StickyNotePanel";
import { isSomeFreeDrawElementSelected } from "../scene/selection";
import { IconDash, IconMaximise, IconPlus, IconZoomOut } from "./newIcons";

export const SelectedShapeActions = ({
  appState,
  elements,
  renderAction,
}: {
  appState: AppState;
  elements: readonly ImagoElement[];
  renderAction: ActionManager["renderAction"];
}) => {
  const targetElements = getTargetElements(
    getNonDeletedElements(elements),
    appState,
  );

  let isSingleElementBoundContainer = false;
  if (
    targetElements.length === 2 &&
    (hasBoundTextElement(targetElements[0]) ||
      hasBoundTextElement(targetElements[1]))
  ) {
    isSingleElementBoundContainer = true;
  }
  const isEditing = Boolean(appState.editingElement);
  const device = useDevice();
  const isRTL = document.documentElement.getAttribute("dir") === "rtl";

  const showFillIcons =
    hasBackground(appState.activeTool.type) ||
    targetElements.some(
      (element) =>
        hasBackground(element.type) && !isTransparent(element.backgroundColor),
    );
  const showChangeBackgroundIcons =
    hasBackground(appState.activeTool.type) ||
    targetElements.some((element) => hasBackground(element.type));

  const showLinkIcon =
    targetElements.length === 1 || isSingleElementBoundContainer;

  let commonSelectedType: string | null = targetElements[0]?.type || null;

  for (const element of targetElements) {
    if (element.type !== commonSelectedType) {
      commonSelectedType = null;
      break;
    }
  }

  return (
    <div className="panelColumn">
      <div style={{ padding: "0 0.625rem" }}>
        {((hasStrokeColor(appState.activeTool.type) &&
          appState.activeTool.type !== "image" &&
          commonSelectedType !== "image") ||
          targetElements.some((element) => hasStrokeColor(element.type))) &&
          renderAction("changeStrokeColor")}
      </div>
      {showChangeBackgroundIcons && (
        <div style={{ padding: "0 0.625rem" }}>{renderAction("changeBackgroundColor")}</div>
      )}
      {showFillIcons && (
        <div style={{ padding: "0 0.625rem" }}>{renderAction("changeFillStyle")}</div>
      )}
      {(hasStrokeWidth(appState.activeTool.type) ||
        targetElements.some((element) => hasStrokeWidth(element.type))) && (
          <div style={{ padding: "0 0.625rem" }}>{renderAction("changeStrokeWidth")}</div>
        )}
      {(appState.activeTool.type === "freedraw" ||
        targetElements.some((element) => element.type === "freedraw")) && (
          <div style={{ padding: "0 0.625rem" }}>{renderAction("changeStrokeShape")}</div>
        )}
      {(appState.activeTool.type === "marker" ||
        targetElements.some((element) => element.type === "marker")) && (
          <div style={{ padding: "0 0.625rem" }}>{renderAction("changeStrokeShape")}</div>
        )}

      {(appState.activeTool.type === "magicpen" ||
        targetElements.some((element) => element.type === "magicpen")) && (
          <div style={{ padding: "0 0.625rem" }}>{renderAction("changeStrokeShape")}</div>
        )}

      {(appState.activeTool.type === "magictext" ||
        targetElements.some((element) => element.type === "magictext")) && (
          <div style={{ padding: "0 0.625rem" }}>{renderAction("changeStrokeShape")}</div>
        )}

      {(appState.activeTool.type === "eraserbig" ||
        targetElements.some((element) => element.type === "eraserbig")) && (
          <div style={{ padding: "0 0.625rem" }}>{renderAction("changeStrokeShape")}</div>
        )}
      {(hasStrokeStyle(appState.activeTool.type) ||
        targetElements.some((element) => hasStrokeStyle(element.type))) && (
          <>
            <div style={{ padding: "0 0.625rem" }}>{renderAction("changeStrokeStyle")}</div>
            <div style={{ padding: "0 0.625rem" }}>{renderAction("changeSloppiness")}</div>
          </>
        )}

      {(canChangeSharpness(appState.activeTool.type) ||
        targetElements.some((element) => canChangeSharpness(element.type))) && (
          <><div style={{ padding: "0 0.625rem" }}>{renderAction("changeSharpness")}</div></>
        )}

      {(hasText(appState.activeTool.type) ||
        targetElements.some((element) => hasText(element.type))) && (
          <>
            <div style={{ padding: "0 0.625rem" }}>{renderAction("changeFontSize")}</div>

            <div style={{ padding: "0 0.625rem" }}>{renderAction("changeFontFamily")}</div>

            <div style={{ padding: "0 0.625rem" }}>{renderAction("changeTextAlign")}</div>
          </>
        )}

      {targetElements.some(
        (element) =>
          hasBoundTextElement(element) || isBoundToContainer(element),
      ) && (
          <div style={{ padding: "0 0.625rem" }}>{renderAction("changeVerticalAlign")}</div>
        )}
      {(canHaveArrowheads(appState.activeTool.type) ||
        targetElements.some((element) => canHaveArrowheads(element.type))) && (
          <div style={{ padding: "0 0.625rem" }}>{renderAction("changeArrowhead")}</div>
        )}

      {(<div style={{ padding: "0 0.625rem" }}>{renderAction("changeOpacity")}</div>)}

      <fieldset>
        <legend>{t("labels.layers")}</legend>
        <div className="buttonList">
          {renderAction("sendToBack")}
          {renderAction("sendBackward")}
          {renderAction("bringToFront")}
          {renderAction("bringForward")}
        </div>
      </fieldset>

      {targetElements.length > 1 && !isSingleElementBoundContainer && (
        <fieldset>
          <legend>{t("labels.align")}</legend>
          <div className="buttonList">
            {
              // swap this order for RTL so the button positions always match their action
              // (i.e. the leftmost button aligns left)
            }
            {isRTL ? (
              <>
                {renderAction("alignRight")}
                {renderAction("alignHorizontallyCentered")}
                {renderAction("alignLeft")}
              </>
            ) : (
              <>
                {renderAction("alignLeft")}
                {renderAction("alignHorizontallyCentered")}
                {renderAction("alignRight")}
              </>
            )}
            {targetElements.length > 2 &&
              renderAction("distributeHorizontally")}
            {/* breaks the row ˇˇ */}
            <div style={{ flexBasis: "100%", height: 0 }} />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: ".5rem",
                marginTop: "-0.5rem",
              }}
            >
              {renderAction("alignTop")}
              {renderAction("alignVerticallyCentered")}
              {renderAction("alignBottom")}
              {targetElements.length > 2 &&
                renderAction("distributeVertically")}
            </div>
          </div>
        </fieldset>
      )}
      {!isEditing && targetElements.length > 0 && (
        <fieldset>
          <legend>{t("labels.actions")}</legend>
          <div className="buttonList">
            {!device.isMobile && renderAction("duplicateSelection")}
            {!device.isMobile && renderAction("deleteSelectedElements")}
            {renderAction("group")}
            {renderAction("ungroup")}
            {showLinkIcon && renderAction("hyperlink")}
          </div>
        </fieldset>
      )}
    </div>
  );
};

export const SelectedElementActions = ({
  appState,
  elements,
  renderAction,
  setAppState,
  handelAutoTextDrawInsertText,
  handwriting
}: {
  appState: AppState;
  elements: readonly ImagoElement[];
  renderAction: ActionManager["renderAction"];
  setAppState: React.Component<any, AppState>["setState"];
  handelAutoTextDrawInsertText?: (url: string, elements: NonDeletedImagoElement[]) => void
  handwriting: Handwriting
}) => {
  const targetElements = getTargetElements(
    getNonDeletedElements(elements),
    appState,
  );

  let isSingleElementBoundContainer = false;
  if (
    targetElements.length === 2 &&
    (hasBoundTextElement(targetElements[0]) ||
      hasBoundTextElement(targetElements[1]))
  ) {
    isSingleElementBoundContainer = true;
  }
  const isEditing = Boolean(appState.editingElement);
  const device = useDevice();
  const isRTL = document.documentElement.getAttribute("dir") === "rtl";

  const showFillIcons =
    hasBackground(appState.activeTool.type) ||
    targetElements.some(
      (element) =>
        hasBackground(element.type) && !isTransparent(element.backgroundColor),
    );
  const showChangeBackgroundIcons =
    hasBackground(appState.activeTool.type) ||
    targetElements.some((element) => hasBackground(element.type));

  const showLinkIcon =
    targetElements.length === 1 || isSingleElementBoundContainer;

  let commonSelectedType: string | null = targetElements[0]?.type || null;

  for (const element of targetElements) {
    if (element.type !== commonSelectedType) {
      commonSelectedType = null;
      break;
    }
  }
  const actions = [];
  if (targetElements.some((element) => canHaveArrowheads(element.type))) {
    actions.push(<React.Fragment key="changeArrowhead">{renderAction("changeArrowhead")}</React.Fragment>);
  }
  if (targetElements.length == 1 && isTextElement(targetElements[0])) {
    actions.push(<React.Fragment key="changeFontSize">{renderAction("changeFontSize")}</React.Fragment>);
  }
  if (showChangeBackgroundIcons) {
    actions.push(<React.Fragment key="changeBackgroundColor">{renderAction("changeBackgroundColor")}</React.Fragment>);
  }
  actions.push(<React.Fragment key="changeOpacity">{renderAction("changeOpacity")}</React.Fragment>);
  actions.push(<React.Fragment key="deleteSelectedElements">{renderAction("deleteSelectedElements")}</React.Fragment>);
  actions.push(<React.Fragment key="duplicateSelection">{renderAction("duplicateSelection")}</React.Fragment>);


  const handleWritingVisible = isSomeFreeDrawElementSelected(getNonDeletedElements(targetElements), appState);
  if (handleWritingVisible) {
    actions.push(<React.Fragment key="handwriting">{renderAction("handwriting", { handwriting, handelAutoTextDrawInsertText })}</React.Fragment>);
  }
  actions.push(<React.Fragment key="setSize">{renderAction("setSize")}</React.Fragment>);
  if (targetElements.length > 1) {
    actions.push(<MultiObjectsAction key="multiObjectsAction" appState={appState} elements={elements} renderAction={renderAction} setAppState={setAppState} />);
  }

  return renderAction("toggleSelectionEditMenu", { actions })

}



export const ShapesSwitcher = ({
  canvas,
  activeTool,
  setAppState,
  onImageAction,
  appState,
  activeRoomId,
  onFlashCollabClick,
  isFlashCollabing
}: {
  canvas: HTMLCanvasElement | null;
  activeTool: AppState["activeTool"];
  setAppState: React.Component<any, AppState>["setState"];
  onImageAction: (data: { pointerType: PointerType | null }) => void;
  appState: AppState;
  activeRoomId?: string;
  onFlashCollabClick?: () => void;
  isFlashCollabing?: boolean;
}) => {

  return (
    <>
      {SHAPES.map(({ value, icon, key, numericKey, fillable }, index) => {
        const label = t(`toolBar.${value}`);
        const letter = key && (typeof key === "string" ? key : key[0]);
        const shortcut = letter
          ? `${capitalizeString(letter)} ${t("helpDialog.or")} ${numericKey}`
          : `${numericKey}`;
        return (
          <ToolButton
            className={clsx("Shape", { fillable })}
            key={value}
            type="radio"
            icon={icon}
            checked={activeTool.type === value}
            name="editor-current-shape"
            title={`${capitalizeString(label)} — ${shortcut}`}
            keyBindingLabel={numericKey}
            aria-label={capitalizeString(label)}
            aria-keyshortcuts={shortcut}
            data-testid={`toolbar-${value}`}
            onPointerDown={({ pointerType }) => {
              // if (!appState.penDetected && pointerType === "pen") {
              //   setAppState({
              //     penDetected: true,
              //     penMode: true,
              //   });
              // }
            }}
            onChange={({ pointerType }) => {
              if (appState.activeTool.type !== value) {
                trackEvent("toolbar", value, "ui");
              }
              const nextActiveTool = updateActiveTool(appState, {
                type: value,
              });
              setAppState({
                activeTool: nextActiveTool,
                multiElement: null,
                selectedElementIds: {},
              });

              setCursorForShape(canvas, {
                ...appState,
                activeTool: nextActiveTool,
              });
              if (value === "image") {
                onImageAction({ pointerType });
              }
            }}
          />
        );
      })}

      {activeRoomId !== null && activeRoomId !== '' && (<ToolFlashCollab appState={appState} isFlashCollabing={isFlashCollabing} onClick={onFlashCollabClick} />)}
    </>
  )
}


export const GroupShapesSwitcher = ({
  canvas,
  activeTool,
  setAppState,
  onImageAction,
  appState,
  renderAction,
  tools,
}: {
  canvas: HTMLCanvasElement | null;
  activeTool: AppState["activeTool"];
  setAppState: React.Component<any, AppState>["setState"];
  onImageAction: (data: { pointerType: PointerType | null, callback: () => void }) => void;
  appState: AppState;
  renderAction: ActionManager["renderAction"];
  tools: any[]
}) => {

  const pickerButton = React.useRef<HTMLButtonElement>(null);

  const renderButton = (shape: any, ref?: any, specialIcon?: JSX.Element) => {
    const { name, value, icon, custom, fillable, group } = shape;


    if (custom) {
      switch (value) {
        case "eraser-all":
          return renderAction("clearCanvas", { showLabel: false, buttonClass: "tool-bar-menu-item" });
        // case "marker":
        //   return <ToolButton type="radio" aria-label={t(`toolBar.${value}`)} title={t(`toolBar.${value}`)} checked={false} icon={icon}/>
      }
    }
    const label = t(`toolBar.${value}`);
    const checked = activeTool.type === value || value === "image" && appState.openSidebar === "imageGallery";



    return value === "magictext" ? (<>

      <ToolButton
        className={clsx("Shape")}
        id={value}
        key={value}
        type="icon"
        icon={specialIcon || icon}
        name="editor-current-shape"
        title={capitalizeString(label)}
        aria-label={capitalizeString(label)}
        data-testid={value}
        onClick={() => {


          setAppState({ isShowSetHandWriteLangPanel: true });

          const nextActiveTool = updateActiveTool(appState, {
            type: value,
            group: group,
          });

          setAppState({
            activeTool: { ...nextActiveTool, locked: true, },
            multiElement: null,
            selectedElementIds: {},
            openPopup: appState.openPopup === group ? null : group,
            currentItemStrokeWidth: value === "marker" ? 8 : appState.currentItemStrokeWidth
          });

          setCursorForShape(canvas, {
            ...appState,
            activeTool: nextActiveTool,
          });


          if (appState.activeTool.type !== value) {
            trackEvent("toolbar", value, "ui");
          }



          setAppState({
            activeTool: { ...nextActiveTool, locked: true, },
            multiElement: null,
            selectedElementIds: {},
          });

          setCursorForShape(canvas, {
            ...appState,
            activeTool: nextActiveTool,
          });

        }}
      />


    </>) : (
      <ToolButton
        className={clsx("Shape", { "toolbar-selected": !!ref && checked })}
        id={value}
        key={value}
        type="radio"
        icon={specialIcon || icon}
        name="editor-current-shape"
        title={capitalizeString(label)}
        checked={!ref && checked}
        //keyBindingLabel={numericKey}
        aria-label={capitalizeString(label)}
        data-testid={value}
        onPointerDown={({ pointerType }) => {
          if (value === "image") {
            setAppState({ openSidebar: "imageGallery" });
            return;
          }

          // if (!appState.penDetected && pointerType === "pen") {
          //   setAppState({
          //     penDetected: true,
          //     penMode: true,
          //   });
          // }

          if (appState.activeTool.type !== value) {
            trackEvent("toolbar", value, "ui");
          }


          const nextActiveTool = updateActiveTool(appState, {
            type: value,
            group: group,
          });

          setAppState({
            activeTool: { ...nextActiveTool, locked: true, },
            multiElement: null,
            selectedElementIds: {},
            openPopup: appState.openPopup === group ? null : group,
            currentItemStrokeWidth: value === "marker" ? 8 : appState.currentItemStrokeWidth
          });

          setCursorForShape(canvas, {
            ...appState,
            activeTool: nextActiveTool,
          });
        }}
        onChange={({ pointerType }) => {

          if (value === "image") {
            setAppState({ openSidebar: "imageGallery" })
            return;
          }
          if (value === "note") {
            setAppState({
              stickyNotes: [
                ...appState.stickyNotes,
                {
                  id: nanoid(),
                  key: nanoid(),
                  content: "",
                  status: "expand",
                  background: StickyNoteBackground[Math.floor((Math.random() * 10) / 2)],
                  fontStyle: "normal",
                  textDecoration: "none",
                  fontWeigth: "normal",
                  position: null
                }
              ]
            })
            return;
          }
          if (appState.activeTool.type !== value) {
            trackEvent("toolbar", value, "ui");
          }

          const nextActiveTool = updateActiveTool(appState, {
            type: value,
            group: group,
          });

          setAppState({
            activeTool: { ...nextActiveTool, locked: true, },
            multiElement: null,
            selectedElementIds: {},
          });

          setCursorForShape(canvas, {
            ...appState,
            activeTool: nextActiveTool,
          });

        }}
        ref={ref}
      />
    )
  }


  const selected = tools.find(t => t.value === appState.activeTool.type) || tools.find(s => s.visible);
  const { group } = selected;
  const isActive = appState.openPopup === group;


  return (
    <div className="toolbar-button-contanier">
      {isActive && tools.length > 1 && <Popover onCloseRequest={(event) => {
        //event.target !== pickerButton.current && setAppState({openPopup: null})

      }
      } bottom={"2.7rem"} left={-4}>
        <Island ><div className="toolbar-pop-contanier">
          {tools.map((t, i) => <React.Fragment key={t.name + i}>{renderButton(t)}</React.Fragment>)}</div></Island></Popover>}
      {renderButton(selected, pickerButton, TOOLBAR_GROUPS[group as keyof typeof TOOLBAR_GROUPS])}

    </div>
  )
}

export const ZoomActions = ({
  renderAction,
  zoom,
}: {
  renderAction: ActionManager["renderAction"];
  zoom: Zoom;
}) => (
  <Stack.Col gap={1} className="zoom-actions">
    <Stack.Row align="center">
      {renderAction("zoomOut", { icon: IconDash })}
      {renderAction("resetZoom")}
      {renderAction("zoomIn", { icon: IconPlus })}
      <div className="App-toolbar__divider"></div>
      {renderAction("zoomToFit", { icon: IconMaximise })}
    </Stack.Row>
  </Stack.Col>
);

export const UndoRedoActions = ({
  renderAction,
  className,
}: {
  renderAction: ActionManager["renderAction"];
  className?: string;
}) => (
  <div className={`undo-redo-buttons ${className}`}>
    <div className="undo-button-container">
      <Tooltip label={t("buttons.undo")}>{renderAction("undo")}</Tooltip>
    </div>
    <div className="redo-button-container">
      <Tooltip label={t("buttons.redo")}> {renderAction("redo")}</Tooltip>
    </div>
  </div>
);

export const ExitZenModeAction = ({
  actionManager,
  showExitZenModeBtn,
}: {
  actionManager: ActionManager;
  showExitZenModeBtn: boolean;
}) => (
  <button
    className={clsx("disable-zen-mode", {
      "disable-zen-mode--visible": showExitZenModeBtn,
    })}
    onClick={() => actionManager.executeAction(actionToggleZenMode)}
  >
    {t("buttons.exitZenMode")}
  </button>
);

export const FinalizeAction = ({
  renderAction,
  className,
}: {
  renderAction: ActionManager["renderAction"];
  className?: string;
}) => (
  <div className={`finalize-button ${className}`}>
    {renderAction("finalize", { size: "small" })}
  </div>
);


export const BottomColorPickerAction = ({
  renderAction,
  items,
  setAppState
}: {
  renderAction: ActionManager["renderAction"];
  items: any[];
  setAppState: React.Component<any, AppState>["setState"];
}) => {
  const shades = (color: string) => {
    switch (color) {
      case "red":
        return oc.red[9];
      case "pink":
        return oc.pink[9];
      case "grape":
        return oc.grape[9];
      case "violet":
        return oc.violet[9];
      case "indigo":
        return oc.indigo[9];
      case "blue":
        return oc.blue[9];
      case "cyan":
        return oc.cyan[9];
      case "teal":
        return oc.teal[9];
      case "green":
        return oc.green[9];
      case "lime":
        return oc.lime[9];
      case "yellow":
        return oc.yellow[9];
      case "orange":
        return oc.orange[9];
      case "gray":
        return oc.gray[7];
      case "black":
        return oc.black;
      case "white":
        return oc.white;
    }
  }

  const colors = items.map(item => item.value || shades(item.name.substring(6, item.name.length)))

  return renderAction("bottomChangeStrokeColor", { "colors": colors, "setAppState": setAppState })

}

export const BottomExtraActions = ({
  setAppState,
  appState,
  renderAction,
  actions,
  canvas
}: {
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  renderAction: ActionManager["renderAction"];
  actions: any[],
  canvas: HTMLCanvasElement | null;
}) => {
  const trigger = React.useRef<HTMLButtonElement>(null);
  const coords = trigger.current?.getBoundingClientRect();

  const value = "enlarge";
  const label = t(`toolBar.${value}`);
  const defaultItem = actions.filter(s => s.default)?.[0]
  const popName = "extraActions";

  const renderButton = (name: string, data: any) => {
    switch (name) {
      // case "zoomin":
      //   return renderAction("zoomIn", data);
      // case "zoomout":
      //   return renderAction("zoomOut", data);
      case "undo":
        return renderAction("undo", data);
      case "redo":
        return renderAction("redo", data);
    }
  };
  const setActive = (active: boolean) => {
    setAppState({ openPopup: active ? popName : null });
  }
  const isActive = appState.openPopup === popName;

  return <div className="toolbar-button-contanier">
    {isActive && <Popover bottom="2.7rem" left={-4}>
      <Island ><div className="toolbar-pop-contanier">
        {actions.map((a, i) => {
          return <React.Fragment key={a.name + i}>{renderButton(a.name, { icon: a.icon })}</React.Fragment>;
        })}</div></Island></Popover>}
    <ToolButton
      className={clsx("Shape", { "toolbar-selected": appState.activeTool.type === value })}
      key={value}
      type="radio"
      icon={GustureIcon}
      name="editor-current-shape"
      title={capitalizeString(label)}
      checked={false}
      //keyBindingLabel={numericKey}
      aria-label={capitalizeString(label)}
      ref={trigger}
      onPointerDown={({ pointerType }) => {
        // if (!appState.penDetected && pointerType === "pen") {
        //   setAppState({
        //     penDetected: true,
        //     penMode: true,
        //   });
        // }

        if (appState.activeTool.type !== value) {
          trackEvent("toolbar", value, "ui");
        }

        setActive(!isActive);
        const nextActiveTool = updateActiveTool(appState, {
          type: value,
        });

        setAppState({
          activeTool: nextActiveTool,
          multiElement: null,
          selectedElementIds: {},
        });

        setCursorForShape(canvas, {
          ...appState,
          activeTool: nextActiveTool,
        });
      }}

    />
  </div>
}

export const BottomMoreActions = ({
  setAppState,
  appState,
  renderAction,
  actions,
  data,
}: {
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  renderAction: ActionManager["renderAction"];
  actions: any[],
  data: any;
}) => {
  const trigger = React.useRef<HTMLDivElement>(null);


  const setActive = (active: boolean) => setAppState({ openPopup: !active ? null : "moreActions" })
  const isActive = appState.openPopup === "moreActions";
  const defaultItem = actions.find((i: any) => i.default)
  const renderButton = (item: any) => {
    switch (item.name) {
      case "library":
        return <LibraryButton
          appState={appState}
          setAppState={setAppState}
          isMobile={true}
          key={item.name}
          icon={item.icon}
        />;

      case "page-list":
        return (
          <button
            className={clsx("menu-item", "tool-bar-menu-item")}
            aria-label={t("labels.pageList")}
            onClick={() => {
              setAppState({ openSidebar: "pageList" });
            }}
            title={t("labels.pageList")}
            type="button"
          >
            <div className="menu-item__icon">{PageListIcon}</div>
          </button>

        );


      case "image": {
        const label = t(`toolBar.${item.name}`);
        return <ToolButton
          className={clsx("Shape")}
          key={item.name}
          type="icon"
          icon={item.icon || ImageGalleryIcon}
          name={item.name}
          title={capitalizeString(label)}
          aria-label={capitalizeString(label)}
          data-testid={item.name}
          onClick={() => {
            setAppState({ openSidebar: appState.lastOpenSidebar || "template" });
          }}
        />
      }
      case "googleDrive": {
        const label = t(`labels.${item.name}`);
        return <ToolButton
          className={clsx("Shape")}
          key={item.name}
          type="icon"
          icon={item.icon || CloudServiceIcon}
          name={item.name}
          title={capitalizeString(label)}
          aria-label={capitalizeString(label)}
          data-testid={item.name}
          onClick={() => {
            setAppState({ openSidebar: appState.lastOpenSidebar || "template" });
          }}
        />
      }
      case "googleSearch": {
        const label = t(`labels.imageSearch`);
        return <ToolButton
          className={clsx("Shape")}
          key={item.name}
          type="icon"
          icon={CloudServiceIcon}
          //icon={item.icon || ImageSearchIcon}
          name={item.name}
          title={capitalizeString(label)}
          aria-label={capitalizeString(label)}
          data-testid={item.name}
          onClick={() => {
            setAppState({ openSidebar: appState.lastOpenSidebar || "template" });
          }}
        />
      }
    }
  }

  return <div className="toolbar-button-contanier">
    {false && <Popover onCloseRequest={(event) => event.target !== trigger.current && setActive(false)} bottom={"2.7rem"} left={-4}>
      <Island><div className="toolbar-pop-contanier">
        {actions.filter(a => !a.default).map(a => renderButton(a))}</div></Island></Popover>}
    <div ref={trigger}>{defaultItem && renderButton(defaultItem)}</div>
  </div>
}



export const FileSaveGroupActions = ({
  setAppState,
  appState,
  renderAction,
  actions,
  data,
  operaPage
}: {
  setAppState: React.Component<any, AppState>["setState"];
  appState: AppState;
  renderAction: ActionManager["renderAction"];
  actions: any[],
  data: any;
  operaPage?: ({ page, actionName }: { actionName?: string, page: string }) => void
}) => {
  const trigger = React.useRef<HTMLDivElement>(null);
  const coords = trigger.current?.getBoundingClientRect();
  const popHeight = actions.filter(a => !a.default).length * 36 + 23;
  const setActive = (active: boolean) => setAppState({ openPopup: !active ? null : "moreFileActions" })
  //const [openMenu, setOpenMenu] = useState(false);

  const isActive = appState.openPopup === "moreFileActions";
  const defaultItem = actions.find((i: any) => i.default)
  const renderButton = (item: any) => {
    switch (item.name) {
      case "file":
        return (
          <button
            className={clsx("menu-item")}
            aria-label={t("buttons.export")}
            onClick={() => {
              setActive(!isActive);
            }}
            title={t("buttons.export")}
            type="button"
          >
            <div className="menu-item__icon">{item.icon}</div>
          </button>

        );
      case "file-save-to":
        return (
          <button
            className={clsx("menu-item")}
            aria-label={t("buttons.export")}
            onClick={() => {
              setActive(!isActive);
              setAppState({ openSidebar: "fileSaveTo" });
            }}
            title={t("buttons.export")}
            type="button"
          >
            <div className="menu-item__icon">{item.icon}</div>
          </button>

        );
      case "file-save":
        return (
          <button
            className={clsx("menu-item")}
            aria-label={t("buttons.fileSave")}
            onClick={async () => {
              setActive(!isActive);
              await saveAsJSONPage(appState, setAppState);
            }}
            title={t("buttons.fileSave")}
            type="button"
          >
            <div className="menu-item__icon">{item.icon}</div>
          </button>

        );

      case "load-file":
        return (
          <button
            className={clsx("menu-item")}
            aria-label={t("buttons.load")}
            onClick={async () => {
              setActive(!isActive);
              await loadFromJSONPage(appState, operaPage);
            }}
            title={t("buttons.load")}
            type="button"
          >
            <div className="menu-item__icon">{item.icon}</div>
          </button>

        );

    }
  }

  return <div className="toolbar-button-contanier">
    {isActive &&
      <Popover onCloseRequest={(event) => event.target !== trigger.current && setActive(false)} bottom={"2.7rem"} left={-4}
      >
        <Island><div className="toolbar-pop-contanier">
          {actions.filter(a => !a.default).map(a => renderButton(a))}</div></Island></Popover>}
    <div ref={trigger}>{defaultItem && renderButton(defaultItem)}</div>
  </div>
}