import "./Profile.scss";
import React, { useState } from "react";
import {
  ExportIcon,
  IconCloseWin,
  LoadIcon,
  OpenIcon,
  ProfileIcon,
  QrScanIcon,
  RecentlyEditedIcon,
  SaveIcon,
  SaveToIcon,
  ScannerCodeIcon,
  SignOutIcon as SignoutIcon,
  UpgradeIcon,
} from "./icons";
import { COOKIES, EmbedType } from "../constants";
import { Popover } from "./Popover";
import { Island } from "./Island";
import { Modal } from "./Modal";
import { AppState } from "../types";
import clsx from "clsx";
import { t } from "../i18n";
import { useAtom } from "jotai";
import { collabAPIAtom } from "../imago-app/collab/Collab";
import {
  delDataFromStorage,
  getCurrPageFromStorage,
  getPageListFromStorage,
} from "../imago-app/data/localStorage";
import { loadFromJSONPage } from "../data/json";
import { saveAsJSONPage } from "../data";
import { FileSaveToModal } from "./FileSaveToModal";
import { UserPlanChange } from "./UserPlanChange";
import { ChangePassword } from "./ChangePassword";
import { ActionManager } from "../actions/manager";
import { actionChangePage } from "../actions/actionChangePage";
import { InsertEmbed } from "./InsertEmbed";
import { LocalData } from "../imago-app/data/LocalData";
import { deleteAllCookies } from "../utils";
import { QRCode } from "antd";
import { ScanDownloadFile } from "./ScanDownloadFile";

export const Profile: React.FC<{
  onDelete: () => void;
  onSave: () => void;
  appState: AppState;
  setAppState: React.Component<any, AppState>["setState"];
  className?: string;
  isMobile?: boolean;
  onRecentlyEdited?: () => void;

  actionManager: ActionManager;
  operaPage?: ({
    page,
    actionName,
  }: {
    actionName?: string;
    page: string;
  }) => void;
}> = ({
  onDelete,
  onSave,
  appState,
  setAppState,
  className,
  isMobile = false,
  onRecentlyEdited,
  actionManager,
  operaPage,
}) => {
    const { isLogined, userInfo } = appState;
    const user = userInfo;

    const [collabAPI] = useAtom(collabAPIAtom);
    const [showPop, setShowPop] = useState<boolean>(false);
    const [signoutCheck, setSignoutCheck] = useState<boolean>(false);
    const [showUserPlanChange, setShowUserPlanChange] = useState<boolean>(false);
    const [showChangePassword, setShowChangePassword] = useState<boolean>(false);
    const [menuState, setMenuState] = useState<{ name: string; show: boolean }[]>(
      [],
    );
    const [showInsertEmbed, setShowInsertEmbed] = useState<{
      show: boolean;
      title?: keyof typeof EmbedType;
    } | null>();
    const [showScanDownload, setShowScanDownload] = useState<boolean>();

    const pickerButton = React.useRef<HTMLDivElement>(null);
    const userPlanButton = React.useRef<HTMLAnchorElement>(null);

    const coords = pickerButton.current?.getBoundingClientRect();
    const onSignout = () => {
      setShowPop(false);
      setSignoutCheck(true);
    };
    const onCancel = () => {
      setSignoutCheck(false);
    };
    const logout = () => {
      // document.cookie = `${COOKIES.AUTH}=;max-age=0;Expires=0`;
      // document.cookie = `${COOKIES.LICENCE}=;max-age=0;Expires=0`;
      // document.cookie = `auth=;max-age=0;Expires=0`;
      deleteAllCookies();
      localStorage.setItem("logout", "1");
      const { userInfo } = appState;
      setAppState({ isLogined: false, userInfo: null });
      delDataFromStorage("google_access_token");
      return fetch("/api/v1/logout", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${userInfo?.authorization}`,
        },
      })
      // .then(() => {
      //   //location.href = "/";
      //   setTimeout(() => {
      //     location.href = "/";
      //   }, 2000)
      // });
    };

    const handleDelete = async () => {
      // if(!collabAPI?.isCollaborating()){
      //   onDelete();
      // }
      await LocalData.clearPages();
      await LocalData.dataStateStorage.clear();

      setSignoutCheck(false);
      logout().then(() => {
        location.href = "/";
      });
    };
    const handleSave = async () => {

      //onDelete();
      logout().then(() => {
        onSave();
        setSignoutCheck(false);
        setTimeout(() => {
          location.href = "/";
        }, 4 * 1000);
      }
      );
    };

    const handleScanQrCode = () => {
      setAppState({ openMenu: null });
      const message = JSON.stringify({
        options: { event: "scan_qr_code" },
      });
      window.ReactNativeWebView.postMessage(message);
    };


    const top = isMobile ? 40 : undefined;
    const bottom = isMobile ? undefined : "2.75rem";
    async function loadFile(): Promise<void> {
      await loadFromJSONPage(appState, operaPage);
      actionManager.executeAction(actionChangePage);
    }

    async function saveFile(): Promise<void> {
      await saveAsJSONPage(appState, setAppState);
    }

    function setShowSaveToPop(open: boolean) {
      setAppState({ openDialog: open ? "fileSaveTo" : null });
    }

    function switchMenu(e: any, name?: string) {
      e.stopPropagation();
      e.preventDefault();
      if (!!!name) {
        setMenuState([]);
        return;
      }
      if (menuState.some((m) => m.name == name)) {
        const mstate = menuState.map((m) => {
          return {
            name: m.name,
            show: name == m.name,
          };
        });
        console.log(mstate)
        setMenuState(
          mstate
        );
      } else {
        setMenuState([...menuState, { name, show: true }]);
      }
    }

    function showMenu(name: string) {
      return menuState.filter((m) => m.name == name)?.[0]?.show;
    }

    return (
      <>
        {isLogined && (
          <div className={clsx("profile", className)} style={{ zIndex: 9999 }}>
            <div
              onClick={() => setShowPop(!showPop)}
              ref={pickerButton}
              className="focal-point"
            >
              {ProfileIcon}
            </div>
            {showPop ? (
              <Popover
                top={top}
                bottom={bottom}
                left={-116}
                onCloseRequest={(e) => {
                  e.target !== pickerButton.current && setShowPop(false);
                  setMenuState([]);
                }}
              >
                <Island style={{ width: 160 }}>
                  <div className="header">
                    <div className="img">{ProfileIcon}</div>
                  </div>
                  <div className="body">
                    <span className="user-name">{user?.username}</span>
                  </div>
                  <div className="footer">
                    <a
                      href={undefined}
                      onPointerEnter={(e) => switchMenu(e)}
                      className="user-plan"
                      ref={userPlanButton}
                      onClick={() => setShowUserPlanChange(true)}
                    >
                      {t(`pricing.${user?.roleEn.toLowerCase()}`)}
                      {user?.isFreeTrial ? ` - ${t("winTabList.free_trial")}` : ""}
                    </a>

                    {/* {window.ReactNativeWebView && (
                    <a onClick={handleScanQrCode}>{t("buttons.scanQrCode")}</a>
                  )} */}
                    <a
                      href={undefined}
                      onPointerEnter={(e) => switchMenu(e)}
                      onClick={() => {
                        setShowChangePassword(true);
                        setShowPop(false);
                      }}
                    >
                      {t("buttons.changepassword")}
                    </a>

                    <a onPointerEnter={(e) => switchMenu(e)} onClick={onSignout}>
                      {t("buttons.signout")}
                    </a>
                  </div>
                </Island>
              </Popover>
            ) : null}
            {signoutCheck ? (
              <Modal
                labelledBy=""
                maxWidth={isMobile ? 244 : 400}
                className="profile"
                onCloseRequest={() => {
                  setSignoutCheck(false);
                }}
              >
                <div className="header">
                  <div className="img">{ProfileIcon}</div>
                </div>
                <div className="body">
                  <span className="body-title">
                    {t("alerts.signoutCheckTitle")}{" "}
                  </span>
                  <span className="body-desc">
                    {t("alerts.signoutCceckDesc")}
                    <br />
                    {t("alerts.signoutCheckWarning")}
                  </span>
                </div>
                <div className="footer">
                  <div className="footer-left">
                    <button className="btn-delete" onClick={handleDelete}>
                      {t("labels.delete")}
                    </button>
                  </div>
                  <div className="footer-right">
                    <button className="" onClick={onCancel}>
                      {t("buttons.cancel")}
                    </button>
                    <button className="btn-primary" onClick={handleSave}>
                      {t("buttons.signoutSave")}
                    </button>
                  </div>
                </div>
              </Modal>
            ) : null}



            {showUserPlanChange ? (
              <Modal
                labelledBy="dialog-title"
                maxWidth={800}
                onCloseRequest={() => {
                  setShowUserPlanChange(false);
                }}
                closeOnClickOutside={true}
              >
                <a
                  className="modal-close"
                  href="#"
                  onClick={() => {
                    setShowUserPlanChange(false);
                  }}
                >
                  {IconCloseWin}
                </a>
                <UserPlanChange
                  onClose={() => {
                    setShowUserPlanChange(false);
                  }}
                />
              </Modal>
            ) : null}
            {showChangePassword && (
              <Modal
                labelledBy="change-password"
                maxWidth={400}
                onCloseRequest={() => {
                  setShowChangePassword(false);
                }}
                closeOnClickOutside={true}
              >
                <div className="modal-close">
                  {" "}
                  <a
                    href="#"
                    onClick={() => {
                      setShowChangePassword(false);
                    }}
                  >
                    {IconCloseWin}
                  </a>
                </div>
                <ChangePassword
                  onClose={() => {
                    setShowChangePassword(false);
                  }}
                />
              </Modal>
            )}

          </div>
        )}
      </>
    );
  };

