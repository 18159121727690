import { ImagoElement, FileId } from "../../element/types";
import {
  clearElementsForExport,
  clearElementsForLocalStorage,
  getScenePagesVersion,
  getSceneVersion,
} from "../../element";
import Portal from "../collab/Portal";
import { restoreElements } from "../../data/restore";
import {
  AppState,
  BinaryFileData,
  BinaryFileMetadata,
  DataURL,
  Page,
} from "../../types";
import {
  FILE_CACHE_MAX_AGE_SEC,
  FILE_ENCRYPTION_KEY,
  STORAGE_KEYS,
} from "../app_constants";
import { decompressData } from "../../data/encode";
import { encryptData, decryptData } from "../../data/encryption";
import { API_URL, MIME_TYPES } from "../../constants";
import { reconcileElements } from "../collab/reconciliation";
import { getSyncableElements, SyncableImagoElement } from ".";
import { loadFromRemoteFlagAtom, PageMap } from "../collab/Collab";
import {
  getCurrPageFromStorage,
  setCurrPageToStorage,
  setPageListToStorage,
} from "./localStorage";
import { jotaiStore } from "../../jotai";
import { LocalData } from "./LocalData";
import { clearAppStateForLocalStorage } from "../../appState";

// private
// -----------------------------------------------------------------------------

let COLLABDATA_CONFIG: Record<string, any>;
try {
  COLLABDATA_CONFIG = {
    baseUrl: "",
    getFilePrefix: "api/v1/getFile",
    deleteFilePrefix: "api/v1/deleteFile",
  };
} catch (error: any) {
  console.warn(`Error JSON parsing collabdata config.`);
  COLLABDATA_CONFIG = {};
}

class CollabDataSceneVersionCache {
  private static cache = new WeakMap<SocketIOClient.Socket, number>();
  static get = (socket: SocketIOClient.Socket) => {
    return CollabDataSceneVersionCache.cache.get(socket);
  };
  static set = (
    socket: SocketIOClient.Socket,
    elements: readonly SyncableImagoElement[],
  ) => {
    CollabDataSceneVersionCache.cache.set(socket, getSceneVersion(elements));
  };

  static setPage = (socket: SocketIOClient.Socket, pages: PageMap) => {
    CollabDataSceneVersionCache.cache.set(socket, getScenePagesVersion(pages));
  };
}

export const isSavedToCollabDataNew = (
  portal: Portal,
  elements: readonly ImagoElement[],
): boolean => {
  if (portal.socket && portal.roomId && portal.roomKey) {
    const sceneVersion = getSceneVersion(elements);

    return CollabDataSceneVersionCache.get(portal.socket) === sceneVersion;
  }
  // if no room exists, consider the room saved so that we don't unnecessarily
  // prevent unload (there's nothing we could do at that point anyway)
  return true;
};

export const isSavedToCollabData = (
  portal: Portal,
  elements: readonly ImagoElement[],
): boolean => {
  if (portal.socket && portal.roomId && portal.roomKey) {
    const sceneVersion = getSceneVersion(elements);

    return CollabDataSceneVersionCache.get(portal.socket) === sceneVersion;
  }
  // if no room exists, consider the room saved so that we don't unnecessarily
  // prevent unload (there's nothing we could do at that point anyway)
  return true;
};
interface CollabDataStoredScene {
  sceneVersion: number;
  elements: readonly SyncableImagoElement[];
  roomKey: string;
}

export interface CollabDataUserStoredScene {
  paginations: Page[];
  pages: PageMap;
  userId: string;
}

interface CollabDataStoredSceneNew {
  sceneVersion: number;
  pages: PageMap;
  currPage: string;
  roomKey: string;
  paginations: Page[];
}

const createCollabDataSceneDocument = async (
  elements: readonly SyncableImagoElement[],
  roomKey: string,
) => {
  const sceneVersion = getSceneVersion(elements);
  return {
    sceneVersion,
    elements,
    roomKey,
  } as CollabDataStoredScene;
};

const createCollabDataSceneDocumentNew = async (
  paginations: Page[],
  pages: PageMap,
  elements: readonly SyncableImagoElement[],
  currPage: string,
  roomKey: string,
) => {
  const sceneVersion = getSceneVersion(elements);
  return {
    sceneVersion,
    pages,
    currPage,
    roomKey,
    paginations,
  } as CollabDataStoredSceneNew;
};

export const loadUserSceneFromStoreData = async (appState: AppState) => {
  const { userInfo } = appState;
  if (userInfo) {
    const response = await fetch(`${API_URL.host}${API_URL.getUserScene}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userInfo.authorization}`,
      },
    });

    if (response.status !== 200) {
      return { currPage: null };
    }
    const data = await response.json();
    if (data.code != 200) {
      return { currPage: null };
    }
    const storedScene = data.data as CollabDataUserStoredScene;


    const store_pages = storedScene.pages as PageMap;
    //await clearPageData()
    await LocalData.clearPages();
    await LocalData.dataStateStorage.clear();
    // for (const page in store_pages) {
    //   const data = store_pages[page];
    //   const dataElements = clearElementsForExport(data || [])
    //   const pageElement = restoreElements(dataElements, null)
    //   savePageDataStateToLocalStorage(page, pageElement, null)

    // }

    await LocalData.pagesStorage.saveAll(store_pages);

    const currentPage =
      storedScene.paginations.length > 0 ? storedScene.paginations[0] : null;
    await LocalData.dataStateStorage.save(
      STORAGE_KEYS.LOCAL_STORAGE_ELEMENTS,
      clearElementsForLocalStorage(store_pages[currentPage?.id || ""]),
    );
    await LocalData.dataStateStorage.save(
      STORAGE_KEYS.LOCAL_STORAGE_APP_STATE,
      clearAppStateForLocalStorage({ ...appState, viewBackgroundColor: currentPage?.backgroundColor }),
    );
    setPageListToStorage(storedScene.paginations);

    //LocalData.save(store_pages[currentPage?.id||""], appState,{},()=>{})
    if (storedScene.paginations.length > 0) {
      setCurrPageToStorage(currentPage?.id || "");
      return {
        currPage: currentPage,
      };
    }
  }
  return {
    currPage: null,
  };
};

export const saveUserSceneData = async (
  pages: Page[],
  pagesContent: PageMap,
  appState: AppState,
) => {
  const { userInfo } = appState;
  if (userInfo) {
    await fetch(`${API_URL.host}${API_URL.saveUserScene}`, {
      method: "POST",
      body: JSON.stringify({ paginations: pages, pages: pagesContent }),
      headers: {
        Authorization: `Bearer ${userInfo.authorization}`,
      },
    });
  }
};

export const saveFilesToCollabData = async ({
  files,
  appState,
}: {
  files: { id: FileId; buffer: Uint8Array }[];
  appState: AppState;
}) => {
  const { userInfo } = appState;
  const erroredFiles = new Map<FileId, true>();
  const savedFiles = new Map<FileId, true>();

  await Promise.all(
    files.map(async ({ id, buffer }) => {
      try {
        await fetch(
          `${API_URL.host}${API_URL.saveFile}/${id}`,
          {
            method: "POST",
            body: new Blob([buffer], {
              type: MIME_TYPES.binary,
            }),
            headers: {
              cacheControl: `public, max-age=${FILE_CACHE_MAX_AGE_SEC}`,
              Authorization: `Bearer ${userInfo?.authorization}`,
            },
          },
        ).then((res) => {
          if (res.status === 200) {
            savedFiles.set(id, true);
          }
        });
      } catch (error: any) {
        console.log("upload fail:", error);
        // erroredFiles.set(id, true);
      }
    }),
  );

  return { savedFiles, erroredFiles };
};

export const saveToCollabDataNew = async (
  portal: Portal,
  paginations: Page[],
  pages: PageMap,
  elements: readonly SyncableImagoElement[],
  appState: AppState,
  isSavedToCollabData: boolean,
) => {
  const { roomId, roomKey, socket } = portal;
  const { userInfo } = appState;

  if (
    // bail if no room exists as there's nothing we can do at this point
    !roomId ||
    !roomKey ||
    !socket ||
    !isSavedToCollabData
  ) {
    return false;
  }

  const savedData = await (async () => {
    const response = await fetch(`${API_URL.host}${API_URL.getScenePrefix}/${roomId}`, {
      headers: {
        Authorization: `Bearer ${userInfo?.authorization}`,
      },
    });
    // .then(async data=>{
    //     return await data.json();
    // });
    if (response.status !== 200) {
      return null;
    }

    const snapshot = (await response.json()).data as CollabDataStoredSceneNew;

    if (!snapshot.currPage) {
      const sceneDocument = await createCollabDataSceneDocumentNew(
        paginations,
        pages,
        elements,
        getCurrPageFromStorage(),
        roomKey,
      );
      for (const p of paginations) {
        var pageMap: PageMap = {};
        pageMap[p.id] = pages[p.id];

        await fetch(`${API_URL.host}${API_URL.saveScenePrefix}/${roomId}`, {
          method: "POST",
          body: JSON.stringify({
            roomId,
            roomKey: sceneDocument.roomKey,
            currPage: sceneDocument.currPage,
            pages: pageMap,
            sceneVersion: sceneDocument.sceneVersion,
            paginations: sceneDocument.paginations,
          }),
          headers: {
            Authorization: `Bearer ${userInfo?.authorization}`,
          },
        });
      }

      return {
        pages,
        reconciledElements: null,
      };
    }

    const sceneDocument = await createCollabDataSceneDocumentNew(
      paginations,
      pages,
      elements,
      getCurrPageFromStorage(),
      roomKey,
    );

    // await fetch(`${COLLABDATA_CONFIG.baseUrl}/${COLLABDATA_CONFIG.saveScenePrefix}/${roomId}`, {
    //   method: "POST",
    //   body: JSON.stringify({
    //     roomId:roomId,
    //     roomKey:sceneDocument.roomKey,
    //     currPage:sceneDocument.currPage,
    //     pages:JSON.stringify(sceneDocument.pages),
    //     sceneVersion:sceneDocument.sceneVersion,
    //     paginations:sceneDocument.paginations
    //   }),
    //   headers: {
    //     Authorization: "Bearer " + userInfo?.authorization
    //   }
    // })

    for (const p of paginations) {
      var pageMap: PageMap = {};
      pageMap[p.id] = pages[p.id];

      await fetch(`${API_URL.host}${API_URL.saveScenePrefix}/${roomId}`, {
        method: "POST",
        body: JSON.stringify({
          roomId,
          roomKey: sceneDocument.roomKey,
          currPage: sceneDocument.currPage,
          pages: pageMap,
          sceneVersion: sceneDocument.sceneVersion,
          paginations: sceneDocument.paginations,
        }),
        headers: {
          Authorization: `Bearer ${userInfo?.authorization}`,
        },
      });
    }

    return {
      pages,
    };
  })();

  savedData && CollabDataSceneVersionCache.setPage(socket, savedData.pages);

  return { reconciledElements: savedData ? savedData.reconciledElements : [] };
};

export interface LoadFromCollabDataResponse {
  elements: readonly ImagoElement[] | null;
  currPage: string;
}

export const loadFromCollabData = async (
  roomId: string,
  roomKey: string,
  socket: SocketIOClient.Socket | null,
  appState: AppState,
): Promise<LoadFromCollabDataResponse> => {
  const { userInfo } = appState;
  //await clearPageData()
  await LocalData.clearPages();
  await LocalData.dataStateStorage.clear();
  const response = await fetch(
    `${API_URL.host}${API_URL.getScenePrefix}/${roomId}`,
    {
      headers: {
        Authorization: `Bearer ${userInfo?.authorization}`,
      },
    },
  );

  if (response.status !== 200) {
    return { elements: null, currPage: "" };
  }

  const storedScene = (await response.json()).data as CollabDataStoredSceneNew;

  if (storedScene && storedScene.pages) {
    // const store_pages = JSON.parse(storedScene.pages.toString()) as PageMap
    const store_pages = storedScene.pages as PageMap;

    const syncableElementsPages: PageMap = {};
    for (const page in store_pages) {
      const elements = getSyncableElements(store_pages[page]);
      syncableElementsPages[page] = elements;
      const data = store_pages[page];
      const dataElements = clearElementsForExport(data || []);
      const pageElement = restoreElements(dataElements, null);

      await LocalData.pagesStorage.save(page, pageElement);
    }

    setPageListToStorage(storedScene.paginations);
    setCurrPageToStorage(storedScene.currPage);

    if (socket) {
      CollabDataSceneVersionCache.setPage(socket, syncableElementsPages);
    }

    const curr_elements = store_pages[storedScene.currPage];

    const el_elements = restoreElements(curr_elements, null);

    return { elements: el_elements, currPage: storedScene.currPage };
  }
  return { elements: null, currPage: "" };
};

export const loadFilesFromCollabData = async (
  roomKey: string,
  filesIds: readonly FileId[],
  appState: AppState,
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();
  const { userInfo } = appState;
  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const decryptionKey = FILE_ENCRYPTION_KEY;
        const url = `${COLLABDATA_CONFIG.baseUrl}/${COLLABDATA_CONFIG.getFilePrefix}/${id}`;
        const response = await fetch(`${url}`, {
          headers: {
            Authorization: `Bearer ${userInfo?.authorization}`,
          },
        });
        if (response.status < 400) {
          const arrayBuffer = await response.arrayBuffer();

          const { data, metadata } = await decompressData<BinaryFileMetadata>(
            new Uint8Array(arrayBuffer),
            {
              decryptionKey,
            },
          );

          const dataURL = new TextDecoder().decode(data) as DataURL;

          loadedFiles.push({
            mimeType: metadata.mimeType || MIME_TYPES.binary,
            id,
            dataURL,
            created: metadata?.created || Date.now(),
            lastRetrieved: metadata?.created || Date.now(),
          });
        } else {
          erroredFiles.set(id, true);
        }
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};


export const loadFilesFromRemote = async (
  filesIds: readonly FileId[],
  auth: string,
) => {
  const loadedFiles: BinaryFileData[] = [];
  const erroredFiles = new Map<FileId, true>();
  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const decryptionKey = FILE_ENCRYPTION_KEY;
        const url = `${COLLABDATA_CONFIG.baseUrl}/${COLLABDATA_CONFIG.getFilePrefix}/${id}`;
        const response = await fetch(`${url}`, {
          headers: {
            Authorization: auth,
          },
        });
        if (response.status < 400) {
          const arrayBuffer = await response.arrayBuffer();

          const { data, metadata } = await decompressData<BinaryFileMetadata>(
            new Uint8Array(arrayBuffer),
            {
              decryptionKey,
            },
          );

          const dataURL = new TextDecoder().decode(data) as DataURL;

          loadedFiles.push({
            mimeType: metadata.mimeType || MIME_TYPES.binary,
            id,
            dataURL,
            created: metadata?.created || Date.now(),
            lastRetrieved: metadata?.created || Date.now(),
          });
        } else {
          erroredFiles.set(id, true);
        }
      } catch (error: any) {
        erroredFiles.set(id, true);
        console.error(error);
      }
    }),
  );

  return { loadedFiles, erroredFiles };
};

export const deleteFilesFromCollabData = async (
  filesIds: readonly string[],
  appState: AppState,
) => {
  const { userInfo } = appState;
  await Promise.all(
    [...new Set(filesIds)].map(async (id) => {
      try {
        const url = `${COLLABDATA_CONFIG.baseUrl}/${COLLABDATA_CONFIG.deleteFilePrefix}/${id}`;
        await fetch(`${url}`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${userInfo?.authorization}`,
          },
        });
      } catch (error: any) {
        console.error(error);
      }
    }),
  );
  return true;
};
